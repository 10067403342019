// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { NavigationStackProp } from "react-navigation-stack";
import { imgPasswordInVisible } from "./assets";
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";
import moment from 'moment';
import { toast } from "react-toastify";
export interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
    branches: boolean
  }
}

export interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
}

interface APIPayloadType {
  contentType?: string;
  endPoint: string;
  method: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType {
  data: UserDetails[];
  message: string;
  meta: {
    message: string;
  };
  students:string
}

interface Student {
  student_name: string;
  student_id: string;
}

interface Agent {
  agent_name: string;
  agent_id: string;
}

interface StudentFilterData {
  students: Student[];
  associate_branch_name: string[];
  created_by: Agent[];
}

export interface StudentAttributes {
  id: string;
  type: string;
  renderIndex:number;
  attributes: {
    student_id: string;
    first_name: string;
    last_name: string;
    gender: string;
    country: string;
    marital_status: string;
    state: string;
    city: string;
    mobile: string;
    email: string;
    emergency_contact_person: string;
    emergency_contact_mobile: string;
    current_status: string;
    residence_country_full_form: string,
    passport_number: string;
    associate_branch_name: string;
    created_at: string;
    updated_at: string;
    agent_name: string;
    agent_id: string;
  };
}

export interface RowsValueResponse {
  sno: number;
  student: string;
  passport: string;
  activeApplication: string;
  associateBranch: string;
  createdBy: string;
  countryAndState: string;
  city: string;
  dateofStudentAdded: string;
  dateOfLastAction: string;
  [key: string]: string | number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  inputData?: Array;
  rawInputData?: Array;
  showdropdown: boolean;
  showSubmitBtn: boolean;
  formId: string;
  headerTitle: string;
  successMsg: string;
  page: number;
  rowsPerPage: number;
  toggleDrawer: boolean,
  userDetails: UserDetails;
  orderBy: string;
  order: "asc" | "desc";
  data: Array<RowsValueResponse>;
  filterOpen: boolean,
  studentValue: string | unknown,
  associateValue: string | unknown,
  value:Student[],
  options: StudentFilterData,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null
  filteredCount: number,
  studentFilterSelected: Student[],
  agentFilterSelected:Agent[]
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeadManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllInputsApiId: string = "";
  postInputDataApiId: string = "";
  getProfileAPICallId: string = "";
  filterDataID: string = "";
  filterData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      alertData: {
        title: "",
        content: "",
        visible: false,
        btnData: [{}],
      },
      inputData: [],
      rawInputData: [],
      showdropdown: false,
      showSubmitBtn: false,
      formId: "",
      headerTitle: "",
      successMsg: "",
      page: 1,
      rowsPerPage: 10,
      toggleDrawer: false,
      userDetails: {} as UserDetails,
      orderBy: "",
      order: 'asc',
      data: [],
      filterOpen: false,
      studentValue: "none",
      associateValue: "none",
      value:[],
      options: [],
      startDate:null,
      endDate: null,
      filteredCount: 0,
      studentFilterSelected:[],
      agentFilterSelected:[]
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiFormSuccessCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.txtInputWebProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source =
        !this.txtInputProps.secureTextEntry && imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: !this.txtInputProps.secureTextEntry && imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgName = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgName.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgName);
  }

  async componentDidMount() {
    await this.getAllInputFields();
  }

  // Customizable Area Start
  sortData = (data: RowsValueResponse[], orderBy: string, order: string) => {
    return [...data].sort((sortingA, sortingB) => {
      const aValue = sortingA.attributes[orderBy];
      const bValue = sortingB.attributes[orderBy];
      if (typeof aValue === "string" && typeof bValue === "string") {
        return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return order === "asc" ? aValue - bValue : bValue - aValue;
      }
      return 0;
    });
  }
  renderResultText = () => {
    const { data, orderBy, order } = this.state;
    const sortedData = this.sortData(data, orderBy, order);
    
    const totalRecords = sortedData.length;
    const rowsPerPage = this.state.rowsPerPage;
    const currentPage = this.state.page;
    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(startIndex + rowsPerPage - 1, totalRecords);
    if (totalRecords === 0) {
      return `${configJSON.showingResult} 0 to 0 of 0`;
    }
  
    return `${configJSON.showingResult} ${startIndex} to ${endIndex} of ${totalRecords}`;
  };
  

  handleRequestSort = (property: string) => {
    const orderBy = property;
    let order: "asc" | "desc" = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }
    this.setState({ orderBy, order });
  };

  paginateData = (data, page, rowsPerPage) => {
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  renderCount = () => {
    const { data, orderBy, order } = this.state;
    const sortedData = this.sortData(data, orderBy, order);
    return Math.ceil(sortedData.length / this.state.rowsPerPage)
  };

  navigateToNewStudentPage = () => {
    const { branches } = this.state.userDetails.attributes;

    if (!branches) {
      toast.error("Please create the branches first before adding the student");
      return;
    }

    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "FormApprovalWorkflow");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  rows = [
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
    { student: 'Dinesh Demo1', id: '106407', country: 'India, Haryana', city: 'Karnal', status: 'Started & Submitted for options', createdBy: 'Dinesh Anand', dateAdded: '01-Nov-2023', lastAction: '21-Dec-2023' },
  ];

  handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
    this.setState({ page: newPage });
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };
  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  testValidateFunction() {
    this.setState({
      inputData: successResponse.data.inputFields,
    });
    this.validateFields();
    this.updateCheckBoxData(true, 2, "Cricket");
    this.updateCheckBoxData(false, 2, "Tennis");
    this.updateCheckBoxData(true, 2, "Football");
    this.updateInputData("text", 1);
    this.updateInputData("text area", 2);
    this.updateInputData("98989", 5);
    this.updateInputData("98989787634", 5);
    this.updateInputData("Male", 3);
    this.validateFields();
    this.updateInputData("", 3);
    this.validateFields();
    this.getInputType("radio");
    this.submitInputData({});
  }

  prepareInputData(responseData) {
    let temp = [];
    let submitBtn = false;
    responseData.map((item) => {
      if (item.field_type?.toLowerCase() == "submit button") {
        submitBtn = true;
      }
      temp.push({
        ...item,
        key: item.label,
        isRequired:
          item.validations?.is_required == "1" ||
            item.validations?.is_required == "true" ||
            item.validations?.is_required == true
            ? true
            : false,
        validationRegex: item.validations?.validation_regex ?? "",
        minLen: item.validations?.min_length
          ? Number(item.validations?.min_length ?? "1")
          : 1,
        maxLen: item.validations?.max_length
          ? Number(item.validations?.max_length ?? "0")
          : 400,
        type: this.getInputType(item.field_type?.toLowerCase()),
        errorMessage: item.label + " is required",
        value: null,
      });
    });
    this.setInputData(temp, submitBtn);
  }

  setInputData(tempData, submitBtn) {
    this.setState({
      inputData: tempData,
      showSubmitBtn: submitBtn,
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  getInputType(type) {
    if (type?.includes("radio")) {
      return configJSON.typeRadio;
    }
    return type;
  }

  updateCheckBoxData(boolVal: boolean, index: number, idStr: string) {
    let temp = this.state.inputData;
    let prevValue = temp[index]?.value;
    if (boolVal) {
      if (prevValue) {
        prevValue.push(idStr);
      } else {
        prevValue = [idStr];
      }
    } else {
      const indStr = prevValue?.indexOf(idStr);
      prevValue?.splice(indStr, 1);
    }
    temp[index].value = prevValue;
    if (prevValue?.length == 0) {
      temp[index].validationError = true;
    } else {
      temp[index].validationError = false;
    }
    this.setState({
      inputData: temp,
    });
  }

  updateInputData(value: string, index: number) {
    let temp = this.state.inputData;
    temp[index].value = value;
    if (temp[index]?.isRequired) {
      if (
        (temp[index].type == configJSON.typeText ||
          temp[index].type == configJSON.typeTextArea) &&
        temp[index].validationRegex &&
        temp[index].validationRegex?.length > 0
      ) {
        const testReg = new RegExp(temp[index].validationRegex);
        if (testReg.test(temp[index].value)) {
          temp[index].validationError = false;
        } else {
          temp[index].validationError = true;
        }
      } else {
        if (temp[index].value?.length == 0) {
          temp[index].validationError = true;
        } else {
          temp[index].validationError = false;
        }
      }
    }
    this.setState({
      inputData: temp,
    });
  }

  validateFields() {
    let temp = this.state.inputData;
    let errorCount = 0;
    let leadFields = [];
    temp.map((item) => {
      if (item.isRequired) {
        if (
          (item.type == configJSON.typeText ||
            item.type == configJSON.typeTextArea) &&
          item.validationRegex &&
          item.validationRegex?.length > 0
        ) {
          const testReg = new RegExp(item.validationRegex);
          if (item.value == null || item.value?.length == 0) {
            item.validationError = true;
            errorCount += 1;
          } else if (testReg.test(item.value)) {
            item.validationError = false;
          }
        } else {
          if (item.value?.length == 0) {
            item.validationError = true;
            errorCount += 1;
          } else {
            item.validationError = false;
          }
        }
      }
      let objLead = {};
      if (item.type == configJSON.typeSubmitButton) {
        objLead["submit_button_value"] = configJSON.btnTitle;
      } else if (item.type == "action email") {
        objLead["action_email"] = item.action_email;
      } else {
        objLead["label"] = item.label;
        if (item.type == configJSON.typeCheckbox) {
          objLead["values"] = item.value;
        } else {
          objLead["value"] = item.value;
        }
      }
      leadFields.push(objLead);
      return item;
    });
    this.setState({
      inputData: temp,
    });
    if (errorCount == 0) {
      this.submitInputData({
        form_id: this.state.formId,
        form_data: leadFields,
      });
    }
  }

  submitInputData = (leadData) => {
    try {
      this.setState({ isLoading: true });

      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postInputDataApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postInputData
      );

      const httpBody = {
        lead: leadData,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  getAllInputFields = async () => {
    await this.getFilterData()
    this.getFormProfileAccount();
    this.callFilterApi();
    this.handleRequestSort("sno");
    try {
      this.setState({ isLoading: true });

      const header = {
        "Content-Type": configJSON.exampleApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllInputsApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getInputDataEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  formApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiFormSuccessCall = (apiRequestCallId: string, responseJson: UserDetailsResponse | StudentFilterData) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.getProfileFormSuccessCallBack(responseJson);
    } 
    if(apiRequestCallId === this.filterDataID) {
      this.setFilterData(responseJson)
    }
    if(apiRequestCallId === this.filterData){
      this.setRecieverFilterData(responseJson)
    }
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson?.data) || responseJson?.message || responseJson?.students;
  };

  getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  getFormProfileAccount = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.formApiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  };

 
  onFilterClick = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
    
    if(this.state.filteredCount === 0) {
      this.handleReset(); 
    }
  };

  handleBranchChange = (event: SelectEventType) => {
    this.setState({ associateValue: event.target.value });
  };

  agentChange = (event: React.ChangeEvent<{}>, newValue: Agent[]) => {
    this.setState({agentFilterSelected:newValue})
  };

  handleSelectionChange = (event: React.ChangeEvent<{}>, newValue: Student[]) => {
    this.setState({studentFilterSelected:newValue})
  };

  handleRemoveItem = (item) => {
    this.setState((prevState) => ({
      studentFilterSelected: prevState.studentFilterSelected.filter(
        (option) => option.student_id !== item
      ),
    }));
  }

  handleRemoveAgent = (item) => {
    this.setState((prevState) => ({
      agentFilterSelected: prevState.agentFilterSelected.filter(
        (option) => option.agent_id !== item
      ),
    }));
  }

  handleStartDateChange = (date) => {
    if (date) {
      this.setState({ startDate: date });
    } else {
      this.setState({ startDate: null, endDate: null });
    }
  };

  handleEndDateChange = (date) => {
    if (date && date.isSameOrAfter(this.state.startDate)) {
      this.setState({ endDate: date });
    } else {
      this.setState({ endDate: null });
    }
  };

  handleReset = () => {
    this.setState({
      studentFilterSelected: [],
      associateValue: 'none',
      agentFilterSelected: [],
      startDate: null,
      endDate: null,
      disableEndDatePicker: true,
      filteredCount: 0
    }, () => {
      this.callFilterApi()
    });
  };

  handleDateReset = () => {
    this.setState({
      startDate: null,
      endDate: null,
    });
  }

  collectFilterData = async () => {
    const { studentFilterSelected, associateValue, agentFilterSelected, startDate, endDate } = this.state;
  
    const filters = {
      studentFilterSelected,
      associateValue,
      agentFilterSelected,
      startDate,
      endDate
    };
  
    const activeFilters = Object.values(filters).filter(val => val && val !== "none" && val.length !== 0).length;
  
    this.setState({ filteredCount: activeFilters }, () => {
      this.onFilterClick();
    });

    this.callFilterApi()
  };

  handleViewStudent = (id: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ViewProfile'
    );
    setStorageData("StudentId", id)
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { id:id })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleViewStudentApplication = (index: string) => {
    setStorageData("isApplication", index)
  }

  callFilterApi = async () => {
    const { studentFilterSelected, agentFilterSelected, startDate, endDate, associateValue } = this.state;
    const baseUrl = configJSON.sendFilterData;
    const params = new URLSearchParams();
  
    const addParamIfNotEmpty = (key, array, idKey) => {
      const values = array.map(item => item[idKey]).filter(Boolean);
      if (values.length) params.append(key, values.join(','));
    };
  
    addParamIfNotEmpty('ids', studentFilterSelected, 'student_id');
    addParamIfNotEmpty('agent_ids', agentFilterSelected, 'agent_id');
  
    const formatDate = date => date ? moment(date).format('YYYY-MM-DD') : null;
  
    const start = formatDate(startDate);
    const end = formatDate(endDate);
  
    if (start) params.append('from', start);
    if (end) params.append('to', end);
  
    if (associateValue && associateValue !== 'none' && associateValue.trim()) {
      params.append('associate_branch_name', associateValue.trim());
    }
  
    const queryString = params.toString();
    this.setState({isLoading:true})
    this.filterData = await this.formApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: queryString ? `${baseUrl}?${queryString}` : baseUrl,
    });
  }
  
  getFilterData = async () => {
    this.filterDataID = await this.formApiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.fetchFilterData
    });
  }

  setFilterData = (responseJson:StudentFilterData) => {
    this.setState({options: responseJson});
  };

  setRecieverFilterData = (responseJson:Array<RowsValueResponse>) => {
   this.setState({isLoading:false,data:responseJson.students.data.map((item, index) => {return { ...item, renderIndex: index+1}})})
  };
  // Customizable Area End
}

let successResponse = {
  data: {
    inputFields: [
      {
        errorMessage: "First Name is required",
        field_type: "Text",
        form_field_id: 1,
        isRequired: false,
        key: "First Name",
        label: "First Name",
        maxLen: 400,
        minLen: 1,
        placeholder: "First Name",
        type: "text",
        validationRegex: "",
        value: "",
        validations: {
          is_required: "0",
          min_length: null,
          max_length: null,
        },
      },
      {
        errorMessage: "Description is required",
        field_type: "Textarea",
        form_field_id: 2,
        isRequired: false,
        key: "Description",
        label: "Description",
        maxLen: 400,
        minLen: 1,
        placeholder: "Description",
        type: "textarea",
        validationRegex: "",
        value: "",
        validations: {
          is_required: "0",
        },
      },
      {
        errorMessage: "Favourite Sports is required",
        field_type: "Checkbox",
        form_field_id: 3,
        isRequired: false,
        key: "Favourite Sports",
        label: "Favourite Sports",
        maxLen: 400,
        minLen: 1,
        type: "checkbox",
        validationRegex: "",
        value: null,
        validations: {
          is_required: "0",
        },
        options: [
          {
            label: "Cricket",
            name: "Cricket",
            value: "Cricket",
            option_id: "Cricket",
          },
          {
            label: "Football",
            name: "Football",
            value: "Football",
            option_id: "Football",
          },
          {
            label: "Tennis",
            name: "Tennis",
            value: "Tennis",
            option_id: "Tennis",
          },
        ],
      },
      {
        errorMessage: "Gender is required",
        field_type: "Radio button",
        form_field_id: 4,
        isRequired: true,
        key: "Gender",
        label: "Gender",
        maxLen: 400,
        minLen: 1,
        type: "radio",
        validationRegex: "",
        value: [],
        validations: {
          is_required: "0",
        },
        options: [
          { label: "Male", name: "Male", value: "Male", option_id: "Male" },
          {
            label: "Female",
            name: "Female",
            value: "Female",
            option_id: "Female",
          },
          { label: "Other", name: "Other", value: "Other", option_id: "Other" },
        ],
      },
      {
        form_field_id: 5,
        field_type: "Submit button",
        value: "Submit",
        type: "submit button",
        validations: {
          is_required: "0",
        },
      },
      {
        errorMessage: "Phone Number is required",
        field_type: "Text",
        form_field_id: 6,
        isRequired: true,
        key: "Phone Number",
        label: "Phone Number",
        maxLen: 20,
        minLen: 1,
        placeholder: "Phone Number",
        type: "text",
        value: "",
        validationRegex:
          "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$",
        validations: {
          is_required: "1",
        },
      },
      {
        lable: "Gender",
        key: "gender",
        isRequired: true,
        validationRegex: "",
        value: "",
        errorMessage: "Please select gender",
        validationError: false,
        minLen: 5,
        maxLen: 16,
        type: "dropdown",
        options: [
          {
            id: 1,
            label: "Male",
          },
          {
            id: 2,
            label: "Female",
          },
          {
            id: 3,
            label: "Other",
          },
        ],
      },
      {
        action_email_id: 2,
        field_type: "Action email",
        action_email: "alex@gmail.com",
        type: "action email",
      },
    ],
  },
};
