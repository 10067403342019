import React from "react";
// Customizable Area Start
import {
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ViewProfileController from "./ViewProfileController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import {
  Card, Table,
  Collapse, Grid,
  Typography, Button,
  TextField, Select,
  RadioGroup, FormControlLabel,
  RadioProps, Radio,
  MenuItem, IconButton,
  Dialog, DialogContent,
  TableSortLabel, TableCell,
  TableRow, TableHead,
  TableBody, Chip,
  Step, Stepper,
  StepLabel, StepConnector,
  withStyles, makeStyles,
  StepIconProps,
  Modal,
  Paper,
  TableContainer
} from "@material-ui/core";
export const configJSON = require("./config");
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  CalliconforAssign, CourtHouseIcon,
  defaultImg, DragAndDrop,
  LocationIcon, Phone_Icon,
  SmsIcon, Tips
} from './assets';
import EditIcon from '@material-ui/icons/Edit';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from "../../../components/src/Loader.web"
import { Formik, FormikErrors, FormikTouched } from "formik";
import {
  CheckCircle, DeleteForever,
  DeleteOutline, Description,
  Image, KeyboardArrowDown,
  PictureAsPdf
} from "@material-ui/icons";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { Bounce, ToastContainer } from "react-toastify";
import {
  FileDownload,
  HourglassEmpty as HourglassEmptyIcon,
  ReportProblem as ReportProblemIcon,
  CheckCircle as CheckCircleIcon
} from "@mui/icons-material";
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import moment from "moment";
import SentRequest from "../../requestmanagement/src/SentRequest.web";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import clsx from "clsx";
import CheckIcon from '@material-ui/icons/Check';
import { Close } from "@material-ui/icons";
// Customizable Area End

export default class ViewProfile extends ViewProfileController {
  // Customizable Area Start

  ViewStudentDetailsCard = () => {
    const { StundetData } = this.state
    return (
      <Card className="StudentDetailsContainer" style={{ padding: "0px" }}>
        <Box className="StundetGenralDetails">
          <Grid container style={{ backgroundColor: "#f9fafc", padding: "20px" }}>
            <Grid item xs={12} md={1}>
              <Box className="ProfilePicContainer" >
                <img
                  src={`${StundetData.image === "" ? defaultImg : StundetData.image}`}
                  className="StudentProfilePic" />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className="StudentFirstNameContainer" >
                <Typography
                  gutterBottom
                  variant="h6"
                  className="ViewStudentHeadig">
                  {StundetData.first_name}
                </Typography>
                <Box className="DetailsCotainer">
                  <Box className="DetailsBox">
                    <Box>
                      <Typography gutterBottom
                        variant="body1"
                        className="GreyColorText" data-test-id="stundentTestID">
                        Student ID - {"  "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{ fontWeight: "700" }}
                        gutterBottom variant="body1">
                        {StundetData.student_id}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="DetailsCotainer">
                  <Box className="DetailsBox" >
                    <Box>
                      <Typography gutterBottom
                        variant="body1" className="GreyColorText">
                        Passport No -{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={{ fontWeight: "700" }}
                        gutterBottom variant="body1">
                        {StundetData.passport_number}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="SecondDetailsContainer">
                <Box>
                  <Box className="IconAndDetailsContainer" >
                    <Box>
                      <img src={Phone_Icon} />
                    </Box>
                    <Box>
                      <Typography
                        className="DarkTextColor"
                        variant="body1">
                        {typeof StundetData.country_code === "string" && "+" + StundetData.country_code?.split("-")[0]}{StundetData.mobile}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="IconAndDetailsContainer" >
                    <Box>
                      <img src={SmsIcon} />
                    </Box>
                    <Box>
                      <Typography
                        className="DarkTextColor"
                        variant="body1">
                        {StundetData.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Button variant="outlined"
                    data-test-id="EditButton"
                    onClick={this.EditFormFuncation}
                    size="large" startIcon={<EditIcon />}
                    className="EditButton">
                    Edit Profile
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="PersonalInfoContainer" >
            <Grid container className="PersonalInfoGridContainer">
              <Grid item md={6} xs={12} className="Section">
                <Grid container className="DetailsContainer">
                  <Grid item xs={6}>
                    <Typography
                    data-test-id="GenderTexttestID"
                      variant="body1"
                      className="Title LightcolorText">
                      Gender
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title BoldTitle">
                      {StundetData.gender}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title LightcolorText">
                      Marital Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title BoldTitle">
                      {StundetData.marital_status}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title LightcolorText">
                      Nationality
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title BoldTitle">
                      {this.state.countryData.filter((item) => {
                        return item.isoCode === StundetData.country_of_nationality
                      })[0]?.name ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ border: "none" }}>
                    <Typography
                      variant="body1"
                      className="Title LightcolorText">
                      Emergency Contact Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ border: "none" }}>
                    <Box className="Title" >
                      <Box className="NameAndDetails">
                        <Box><Typography variant="body1" className="smallTitle" >Name:</Typography></Box>
                        <Box>
                          <Typography variant="body1" className="BoldTitle">
                            {StundetData.emergency_contact_person}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="NameAndDetails">
                        <Box>
                          <Typography
                            variant="body1"
                            className="smallTitle">
                            Mobile:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            className="BoldTitle">
                            {typeof this.state.emercontryCodeValue === "string" && "+" + this.state.emercontryCodeValue?.split("-")[0]}{StundetData.emergency_contact_mobile}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="NameAndDetails">
                        <Box>
                          <Typography
                            variant="body1"
                            className="smallTitle">
                            Email:
                          </Typography>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Typography
                            noWrap
                            variant="body1"
                            style={{
                              textTransform: "unset"
                            }}
                            className="BoldTitle">
                            {StundetData.emergency_contact_email}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className="Section">
                <Grid container className="DetailsContainer removepadding">
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title LightcolorText">
                      Residence
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title BoldTitle">
                      {this.state.countryData.filter((item) => {
                        return item.isoCode === StundetData.country_of_residence
                      })[0]?.name ?? "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title LightcolorText">
                      Student Address
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title BoldTitle">
                      {StundetData.city}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className="Title LightcolorText">
                      Associated Branch
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1" noWrap
                      className="Title BoldTitle">
                      {StundetData.associate_branch_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body1"
                      className="Title LightcolorText">
                      Visa Refusal
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" className="Title BoldTitle">
                      {StundetData.visa_refusal.length === 0 ? "No refusals" :
                        <Box>
                          <Box className="ContantryAndDateClass">
                            <Typography variant="body1"
                              className="Title LightcolorText">
                              Name :
                            </Typography>
                            <Typography variant="body1"
                              className="Title BoldTitle">
                              {StundetData.visa_refusal[0].fullForm}
                            </Typography>
                          </Box>
                          <Box className="ContantryAndDateClass">
                            <Typography variant="body1"
                              className="Title LightcolorText">
                              Date :
                            </Typography>
                            <Typography variant="body1"
                              className="Title BoldTitle"> {moment(StundetData.visa_refusal[0].date).format("DD-MM-YYYY")}
                            </Typography>
                            {
                              StundetData.visa_refusal.length > 1 &&
                              <Tooltip title="Read more..">
                                <InfoIcon data-test-id="VisaRefusalPop" onClick={() => {
                                  this.OpenVisaAndTravelModelOpen("Visa refusal Data", StundetData.visa_refusal)
                                }} className="ReadMore" />
                              </Tooltip>
                            }
                          </Box>
                        </Box>
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1"
                      className="Title LightcolorText">
                      Travel History
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1"
                      className="Title BoldTitle">
                      {StundetData.travel_history.length === 0 ? "Not traveled abroad" :
                        <Box>
                          <Box className="ContantryAndDateClass">
                            <Typography variant="body1"
                              className="Title LightcolorText">
                              Name :
                            </Typography>
                            <Typography variant="body1"
                              className="Title BoldTitle">
                              {StundetData.travel_history[0].fullForm}
                            </Typography>
                          </Box>
                          <Box className="ContantryAndDateClass">
                            <Typography variant="body1"
                              className="Title LightcolorText">
                              Date :
                            </Typography>
                            <Typography variant="body1"
                              className="Title BoldTitle"> {moment(StundetData.travel_history[0].date).format("DD-MM-YYYY")}
                            </Typography>
                            {StundetData.travel_history.length > 1 &&
                              <Tooltip title="Read more..">
                                <InfoIcon data-test-id="TravelHistoryPop" onClick={() => {
                                  this.OpenVisaAndTravelModelOpen("Travel History Data", StundetData.travel_history)
                                }} className="ReadMore" />
                              </Tooltip>
                            }
                          </Box>
                        </Box>
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    )
  }
  errorCounter = (errors: string | undefined, touched: string | boolean | undefined) => {
    return errors && touched && (
      <RequiredTxt>{errors}</RequiredTxt>
    )
  }

  getErrorMessageForOption = (
    touched: FormikTouched<{ AddVisaRefusal: { country: string; date: string; }[]; TravelHistory: { country: string; date: string; }[]; }>,
    errors: FormikErrors<{ AddVisaRefusal: { country: string; date: string; }[]; TravelHistory: { country: string; date: string; }[]; }>,
    idx: number,
    key: "country" | "date" = "country",
    field: "AddVisaRefusal" | "TravelHistory" = "AddVisaRefusal"
  ) => {
    if (errors[field] && touched[field]) {
      const errorField = errors[field] && errors[field]?.[idx];
      const touchedField = touched[field] && touched[field]?.[idx];
      if (touchedField && typeof errorField === 'object' && errorField !== null && key in errorField) {
        return <RequiredTxt>{errorField[key]}</RequiredTxt>;
      }
      return null;
    }

  }

  getStatusIcon = (status: string) => {
    if (status === "pending") {
      return <Tooltip title="pending">
        <HourglassEmptyIcon style={{ color: '#FFA500' }} />
      </Tooltip>
    }
    if (status === "fix_required") {
      return <Tooltip title="Fix required">
        <ReportProblemIcon style={{ color: '#FF0000' }} />
      </Tooltip>
    }
    if (status === "done") {
      return <Tooltip title="Done">
        <CheckCircleIcon style={{ color: '#4CAF50' }} />
      </Tooltip>
    }
  };

  EditStudentDetailsForm = () => {
    const { EditFormData } = this.state

    const today = new Date();
    const hundredYearsAgo = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
    const maxDate = today.toISOString().split('T')[0];
    const minDate = hundredYearsAgo.toISOString().split('T')[0];

    return (
      <Card className="StudentDetailsContainer" style={{ padding: "0px" }}>
        <Box className="StundetGenralDetails">
          <Formik
            initialValues={{
              FirstName: EditFormData.FirstName,
              LastName: EditFormData.LastName,
              Gender: EditFormData.Gender,
              MaritalStatus: EditFormData.MaritalStatus,
              Countryofnationality: EditFormData.Countryofnationality,
              Countryofresidence: EditFormData.Countryofresidence,
              State: EditFormData.State,
              City: EditFormData.City,
              PassportNumber: EditFormData.PassportNumber,
              Mobile: EditFormData.Mobile,
              Email: EditFormData.Email,
              AssociatedBranch: EditFormData.AssociatedBranch,
              EmergencyContactPerson: EditFormData.EmergencyContactPerson,
              EmergencyMobile: EditFormData.EmergencyMobile,
              EmergencyEmail: EditFormData.EmergencyEmail,
              AddVisaRefusal: EditFormData.AddVisaRefusal,
              TravelHistory: EditFormData.TravelHistory
            }}
            enableReinitialize={this.state.FormikFormReinitialize}
            validateOnChange={true}
            validationSchema={this.StudentUpdateSchema}
            validateOnBlur={true}
            onSubmit={async (values) => {
              const { isPassportErrorValid, IsEmergencyMobileValid, IsStudentMobileValid } = this.state
              if (!isPassportErrorValid && !IsEmergencyMobileValid && !IsStudentMobileValid) {
                await this.OnSubmitFuncation(values)
              }
            }}
          >
            {({ handleSubmit, setFieldValue, handleBlur, handleChange, errors, values, touched }) => {
              const ImageCondtion = EditFormData.UserImage === "";
              return (
                <form data-test-id="MangeStaffSubmitTestID" className="EditForm" onSubmit={handleSubmit} autoComplete="off">
                  <Grid container style={{ backgroundColor: "#f9fafc", padding: "20px" }}>
                    <Grid item xs={12} md={6}>
                      <Box className="ProfilePicContainer EditProfile" >
                        <img
                          src={`${ImageCondtion ? defaultImg : EditFormData.UserImage}`}
                          className="StudentProfilePic" />
                        {
                          ImageCondtion ? <Button
                            component="label"
                            variant="outlined"
                            tabIndex={-1}
                            size="small"
                            startIcon={<UploadIcon />}
                            className={"ProfileUpdateButton "}
                          >
                            Upload photo
                            <input
                              type="file"
                              style={{ display: "none" }}
                              data-test-id="UserImage"
                              onChange={(event) => {
                                this.UploadImage(event)
                              }}
                            />
                          </Button> : <Button
                            variant="outlined"
                            tabIndex={-1}
                            size="small"
                            onClick={() => {
                              this.DeleteUploadProfilePic()
                            }}
                            data-test-id="DeletePhoto"
                            startIcon={<DeleteIcon />}
                            className={"ProfileUpdateButton " + " ErrorText "}
                          >
                            Delete photo
                          </Button>
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="SecondDetailsContainer EditDetailsContainer">
                        <Box>
                          <Button variant="outlined" size="large" type="submit" className="EditButton SaveChanges">
                            Save Changes
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className="PersonalInfoContainer">
                    <Grid container spacing={10}>
                      <Grid item xs={12} md={6} className="container-personal-wrap">
                        <Grid container className="container-personal">
                          <Grid item xs={12} md={6}>
                            <LabelsTypography>
                              First Name<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <TextField
                              type="text"
                              placeholder={"Enter Name"}
                              className="inputField"
                              onChange={(e) => {
                                this.onChangeOfStudentData(e, setFieldValue)
                              }}
                              value={values.FirstName}
                              onBlur={handleBlur}
                              name={"FirstName"}
                              data-test-id="StudentFirstName"
                              error={!!errors.FirstName}
                              fullWidth
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                            {this.errorCounter(errors.FirstName, touched.FirstName)}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LabelsTypography>
                              Last Name <StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <TextField
                              type="text"
                              className="inputField"
                              placeholder={"Enter Last Name"}
                              onChange={(e) => {
                                this.onChangeOfStudentData(e, setFieldValue)
                              }}
                              value={values.LastName}
                              name={"LastName"}
                              onBlur={handleBlur}
                              data-test-id="StudentLastName"
                              error={!!errors.LastName}
                              fullWidth
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                            {this.errorCounter(errors.LastName, touched.LastName)}
                          </Grid>
                          <Grid item xs={6}>
                            <LabelsTypography>
                              Gender
                              <StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              defaultValue={values.Gender}
                              data-test-id="RadioGroupTestGender"
                            >
                              <FormControlLabel data-test-id="genderTestId" name="gender" value="Male"
                                control={<RadioButton />}
                                onChange={() => {
                                  this.OnChangeOfStudentDataRadio("Male", 'Gender', setFieldValue)
                                }}
                                label="Male" />
                              <FormControlLabel data-test-id="genderTestId1" name="gender" value="Female"
                                control={<RadioButton />}
                                label="Female"
                                onChange={() => {
                                  this.OnChangeOfStudentDataRadio("Female", 'Gender', setFieldValue)
                                }}
                              />
                            </RadioGroup>
                            {this.errorCounter(errors.Gender, touched.Gender)}
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Marital Status <StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              data-test-id="RadioGroupTestMarital"
                              defaultValue={values.MaritalStatus}
                            >
                              <FormControlLabel name="MaritalStatus" data-test-id="maritalStatusTestId1" value="married" control={<RadioButton />} label="Married"
                                onChange={() => {
                                  this.OnChangeOfStudentDataRadio("married", 'MaritalStatus', setFieldValue)
                                }}
                              />
                              <FormControlLabel data-test-id="maritalStatusTestId" name="MaritalStatus" value="unmarried"
                                onChange={() => {
                                  this.OnChangeOfStudentDataRadio("unmarried", 'MaritalStatus', setFieldValue)
                                }}
                                control={<RadioButton />} label="Unmarried" />
                            </RadioGroup>
                            {this.errorCounter(errors.MaritalStatus, touched.MaritalStatus)}
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Country of Nationality<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <SelectInput
                              displayEmpty
                              MenuProps={{
                                getContentAnchorEl: null,
                                disableScrollLock: true,
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom"
                                },
                              }}
                              name="Countryofnationality"
                              value={values.Countryofnationality}
                              IconComponent={KeyboardArrowDown}
                              data-test-id="StudentCountryofnationality"
                              defaultValue="default"
                              className="selectField"
                              onChange={handleChange}
                            >
                              {values.Countryofnationality === "" && (
                                <MenuItem value="" disabled>
                                  Select Country of Nationality
                                </MenuItem>
                              )}
                              {this.state.countryData.map((items, index2) => (
                                <MenuItem key={index2} value={items.isoCode}>{items.name}</MenuItem>
                              ))}
                            </SelectInput>
                            {this.errorCounter(errors.Countryofnationality, touched.Countryofnationality)}
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Country of Residence<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <SelectInput
                              displayEmpty
                              MenuProps={{
                                getContentAnchorEl: null,
                                disableScrollLock: true,
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom"
                                },
                              }}
                              name="Countryofresidence"
                              value={values.Countryofresidence}
                              IconComponent={KeyboardArrowDown}
                              data-test-id="Countryofresidence"
                              onChange={(e) => {
                                this.HandelCountryOfresidence(e, setFieldValue)
                              }}
                            >
                              {values.Countryofnationality === "" && (
                                <MenuItem value="" disabled>
                                  Select Country of Residence
                                </MenuItem>
                              )}
                              {this.state.countryData.map((items, index2) => (
                                <MenuItem key={index2} value={items.isoCode}>{items.name}</MenuItem>
                              ))}
                            </SelectInput>
                            {this.errorCounter(errors.Countryofresidence, touched.Countryofresidence)}
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              State<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <SelectInput
                              displayEmpty
                              value={values.State}
                              MenuProps={{
                                getContentAnchorEl: null,
                                disableScrollLock: true,
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom"
                                },
                              }}
                              IconComponent={KeyboardArrowDown}
                              name="State"
                              data-test-id="StudentState"
                              defaultValue="default"
                              onChange={handleChange}
                            >
                              {values.State == "" && (
                                <MenuItem disabled value="">
                                  Select State
                                </MenuItem>
                              )}
                              {this.state.stateData.map((item: { name: string }) => (
                                <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                              ))}
                            </SelectInput>
                            {this.errorCounter(errors.State, touched.State)}
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              City<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <TextField
                              onChange={(e) => {
                                this.onChangeOfStudentData(e, setFieldValue)
                              }}
                              name={"City"}
                              type="text"
                              placeholder={"Enter City"}
                              className="inputField"
                              value={values.City}
                              onBlur={handleBlur}
                              data-test-id="StudentCity"
                              error={!!errors.City}
                              fullWidth
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                            {this.errorCounter(errors.City, touched.City)}
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Passport Number<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <TextField
                              type="text"
                              name={"PassportNumber"}
                              placeholder={"Enter Passport Number"}
                              className="inputField"
                              onChange={(e) => {
                                this.onChangeOfStudentData(e, setFieldValue)
                              }}
                              value={values.PassportNumber}
                              onBlur={handleBlur}
                              data-test-id="StudentPassportNumber"
                              error={!!errors.PassportNumber}
                              inputProps={{
                                maxLength: 30,
                              }}
                              fullWidth
                            />
                            {
                              this.state.isPassportErrorValid ? <RequiredTxt>{this.state.passportErrorMessage}</RequiredTxt> :
                                this.errorCounter(errors.PassportNumber, touched.PassportNumber)
                            }
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Mobile
                              <StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <Box style={{ display: "flex", gap: "24px" }}>
                              <Box>
                                <SelectInput
                                  displayEmpty
                                  value={this.state.contryCodeValue}
                                  IconComponent={KeyboardArrowDown}
                                  data-test-id="MoileContry"
                                  defaultValue="default"
                                  onChange={(e) => {
                                    this.handleCountryCodeChange(e, "contryCodeValue")
                                  }}
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    disableScrollLock: true,
                                    anchorOrigin: {
                                      horizontal: "left",
                                      vertical: "bottom"
                                    },
                                  }}
                                >
                                  {this.state.countryCodes.map((item) => (
                                    <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>{`+${item.country_code} (${item.alpha2})`}</MenuItem>
                                  ))}
                                </SelectInput>
                              </Box>
                              <Box className="MobileNumberTextFieldContainer">
                                <TextField
                                  type="text"
                                  placeholder={"Enter mobile number"}
                                  className="inputField"
                                  onChange={(e) => {
                                    this.onChangeOfStudentData(e, setFieldValue)
                                  }}
                                  name="Mobile"
                                  value={values.Mobile}
                                  onBlur={handleBlur}
                                  data-test-id="StudentMobile"
                                  fullWidth
                                  error={!!errors.Mobile}
                                  inputProps={{
                                    maxLength: 30,
                                  }}
                                />
                                {this.state.IsStudentMobileValid ?
                                  <RequiredTxt>{this.state.IsStudentMobileText}</RequiredTxt> :
                                  this.errorCounter(errors.Mobile, touched.Mobile)
                                }
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Email<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <TextField
                              type="text"
                              placeholder={"Enter Email"}
                              className="inputField"
                              onChange={(e) => {
                                this.onChangeOfStudentData(e, setFieldValue)
                              }}
                              name={"Email"}
                              value={values.Email}
                              onBlur={handleBlur}
                              data-test-id="StudentEmail"
                              error={!!errors.Email}
                              fullWidth
                            />
                            {this.errorCounter(errors.Email, touched.Email)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} className="container-personal-wrap-down">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Associated Branch<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <SelectInput
                              displayEmpty
                              MenuProps={{
                                getContentAnchorEl: null,
                                disableScrollLock: true,
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom"
                                },
                              }}
                              name="AssociatedBranch"
                              value={values.AssociatedBranch}
                              IconComponent={KeyboardArrowDown}
                              data-test-id="StudentAssociatedBranch"
                              defaultValue="default"
                              onChange={handleChange}
                            >
                              {values.AssociatedBranch === "" && (
                                <MenuItem value="Select Associated Branch" disabled>
                                  Select Associated Branch
                                </MenuItem>
                              )}
                              {this.state.AssociateName.map((item) => {
                                return <MenuItem value={item.name}>{item.name}</MenuItem>
                              })}
                            </SelectInput>
                            {this.errorCounter(errors.AssociatedBranch, touched.AssociatedBranch)}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h2" className="HeadingEmergency">
                              Emergency Contact & Other Documents
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Contact Person
                              <StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <TextField
                              onChange={(e) => { this.onChangeOfStudentData(e, setFieldValue) }}
                              name={"EmergencyContactPerson"}
                              value={values.EmergencyContactPerson}
                              onBlur={handleBlur}
                              type="text"
                              className="inputField"
                              placeholder={"Enter Contact Person"}
                              data-test-id="StudentEmergencyContactPerson"
                              error={!!errors.EmergencyContactPerson}
                              fullWidth
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                            {this.errorCounter(errors.EmergencyContactPerson, touched.EmergencyContactPerson)}
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Mobile
                              <StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <Box style={{
                              display: "flex",
                              gap: "24px"
                            }}>
                              <Box>
                                <SelectInput
                                  displayEmpty
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    disableScrollLock: true,
                                    anchorOrigin: {
                                      horizontal: "left",
                                      vertical: "bottom"
                                    },
                                  }}
                                  value={this.state.emercontryCodeValue}
                                  IconComponent={KeyboardArrowDown}
                                  data-test-id="MoileContryEmergncy"
                                  defaultValue="default"
                                  onChange={(e) => {
                                    this.handleCountryCodeChange(e, "emercontryCodeValue")
                                  }}
                                >
                                  {this.state.countryCodes.map((item) => (
                                    <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>{`+${item.country_code} (${item.alpha2})`}</MenuItem>
                                  ))}
                                </SelectInput>
                              </Box>
                              <Box className="MobileNumberTextFieldContainer">
                                <TextField
                                  type="text"
                                  placeholder={"Enter Mobile Number"}
                                  className="inputField"
                                  name={"EmergencyMobile"}
                                  value={values.EmergencyMobile}
                                  data-test-id="StudentEmergencyMobile"
                                  onChange={(e) => {
                                    this.onChangeOfStudentData(e, setFieldValue)
                                  }}
                                  error={!!errors.EmergencyMobile}
                                  fullWidth
                                  inputProps={{
                                    maxLength: 30,
                                  }}
                                />
                                {this.state.IsEmergencyMobileValid ?
                                  <RequiredTxt>{this.state.IsEmergencyMobileText}</RequiredTxt> :
                                  this.errorCounter(errors.EmergencyMobile, touched.EmergencyMobile)}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <LabelsTypography>
                              Email<StarTxt>{configJSON.starTxt}</StarTxt>
                            </LabelsTypography>
                            <TextField
                              type="text"
                              placeholder={"Enter Email"}
                              className="inputField"
                              data-test-id="StudentEmergencyEmail"
                              onChange={(e) => {
                                this.onChangeOfStudentData(e, setFieldValue)
                              }}
                              name={"EmergencyEmail"}
                              value={values.EmergencyEmail}
                              onBlur={handleBlur}
                              error={!!errors.EmergencyEmail}
                              fullWidth
                            />
                            {this.errorCounter(errors.EmergencyEmail, touched.EmergencyEmail)}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h2" className="HeadingEmergency">
                              Add Visa Refusal
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {
                              values.AddVisaRefusal.map((item, index) => {
                                return (
                                  <Grid container spacing={2} key={item.date + index} >
                                    <Grid item xs={6}>
                                      <LabelsTypography>
                                        <StarTxt>Country</StarTxt>
                                      </LabelsTypography>
                                      <SelectInput
                                        displayEmpty
                                        data-test-id={"stateCountryCodeTestId" + index}
                                        MenuProps={{
                                          getContentAnchorEl: null,
                                          disableScrollLock: true,
                                          anchorOrigin: {
                                            horizontal: "left",
                                            vertical: "bottom"
                                          },
                                        }}
                                        IconComponent={KeyboardArrowDown}
                                        defaultValue="default"
                                        onChange={(e) => {
                                          this.onChnageAddVisaRefusalContry(e, setFieldValue, index, values.AddVisaRefusal)
                                        }}
                                        value={item.country}
                                      >
                                        {item.country === "" && (
                                          <MenuItem value="" disabled>
                                            Select Country
                                          </MenuItem>
                                        )}
                                        {this.state.countryData.map((items, index2) => (
                                          <MenuItem key={index2} value={items.isoCode}>{items.name}</MenuItem>
                                        ))}
                                      </SelectInput>
                                      {this.getErrorMessageForOption(touched,
                                        errors,
                                        index,
                                        "country",
                                        "AddVisaRefusal"
                                      )}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Box>
                                        <LabelsTypography>
                                          <StarTxt>Date</StarTxt>
                                        </LabelsTypography>
                                        <Box className="CountryDateContainer">                                          <Box style={{ width: "100%" }}>
                                          <TextField
                                            type="date"
                                            placeholder={"Enter Date"}
                                            className="inputField"
                                            onChange={(e) => {
                                              this.onChnageAddVisaRefusDate(e, setFieldValue, index, values.AddVisaRefusal)
                                            }}
                                            name={"VisaDate" + index}
                                            value={item.date}
                                            onBlur={handleBlur}
                                            data-test-id={"VisaDate" + index}
                                            fullWidth
                                            inputProps={{
                                              maxLength: 30,
                                              min: minDate,
                                              max: maxDate
                                            }}
                                          />
                                          {this.getErrorMessageForOption(touched, errors, index, "date", "AddVisaRefusal")}
                                        </Box>
                                          <Button name="AddVisaRefusal" data-test-id={"VisaAddDelete" + index} onClick={() => {
                                            this.DeleteAddVisaRefusal(values.AddVisaRefusal, setFieldValue, index)
                                          }}>
                                            <DeleteForever />
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )
                              })
                            }
                            <Button className="AddAnother" name="AddVisaRefusal" data-test-id="VisaAdd" onClick={() => {
                              this.AddVisaRefusal(values.AddVisaRefusal, setFieldValue)
                            }} startIcon={<AddIcon />}>
                              Add Another
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h2" className="HeadingEmergency">
                              Add Travel History
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {
                              values.TravelHistory.map((item, index) => {
                                return (
                                  <Grid container spacing={2} key={item.date + index}>
                                    <Grid item xs={6}>
                                      <LabelsTypography>
                                        <StarTxt>Country</StarTxt>
                                      </LabelsTypography>
                                      <SelectInput
                                        displayEmpty
                                        MenuProps={{
                                          getContentAnchorEl: null,
                                          disableScrollLock: true,
                                          anchorOrigin: {
                                            horizontal: "left",
                                            vertical: "bottom"
                                          },
                                        }}
                                        value={item.country}
                                        IconComponent={KeyboardArrowDown}
                                        data-test-id={"TravelHistoryCountryCode" + index}
                                        defaultValue="default"
                                        onChange={(e) => {
                                          this.onChnageTravelHistoryContry(e, setFieldValue, index, values.TravelHistory)
                                        }}
                                      >
                                        {item.country === "" && (
                                          <MenuItem value="" disabled>
                                            Select Country
                                          </MenuItem>
                                        )}
                                        {this.state.countryData.map((items, index2) => (
                                          <MenuItem key={index2} value={items.isoCode}>{items.name}</MenuItem>
                                        ))}
                                      </SelectInput>
                                      {this.getErrorMessageForOption(touched, errors, index, "country", "TravelHistory")}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <LabelsTypography>
                                        <StarTxt>Date</StarTxt>
                                      </LabelsTypography>
                                      <Box
                                        className="CountryDateContainer">                                        <Box style={{ width: "100%" }} >
                                          <TextField
                                            type="date"
                                            placeholder={"Enter Date"}
                                            className="inputField"
                                            onChange={(e) => {
                                              this.onChnageTravelHistoryDate(e, setFieldValue, index, values.TravelHistory)
                                            }}
                                            name="name"
                                            value={item.date}
                                            onBlur={handleBlur}
                                            data-test-id={"TravelHistoryDate" + index}
                                            fullWidth
                                            inputProps={{
                                              maxLength: 30,
                                              min: minDate, max: maxDate
                                            }}
                                          />
                                          {this.getErrorMessageForOption(touched, errors, index, "date", "TravelHistory")}
                                        </Box>
                                        <Button data-test-id={"DeleteTravelHistory" + index} onClick={() => {
                                          this.DeleteTravelHistory(values.TravelHistory, setFieldValue, index)
                                        }}>
                                          <DeleteForever />
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )
                              })
                            }
                            <Button onClick={() => {
                              this.AddTravelHistory(values.TravelHistory, setFieldValue)
                            }}
                              name="AddTravelHistory"
                              className="AddAnother"
                              data-test-id="AddTravelHistory"
                              startIcon={<AddIcon />}>
                              Add Another
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )
            }}
          </Formik>
        </Box>
      </Card>
    )
  }

  showErrorToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="colored"
        transition={Bounce}
      />
    )
  }

  RenderActiveTab = (index: number) => {
    if (index === 0) {
      return this.StudentDoucmentRedner()
    }
    if (index === 1) {
      return this.ApplicationsDataRender()
    }
    if (index === 2) {
      return this.ShortListDataRender()
    }
    if (index === 3) {
      return this.AssignedStaffTabRender()
    }
    if (index === 4) {
      return this.TransactionsDataRender()
    }
  }

  ApplicationsDataRender = () => {
    return (
      <ApplicationsStyled className="TabDetailsContainer" style={{ height: "auto", backgroundColor: "white" }} >
        <Box>
          <TableContainer component={Paper} className="TableContaier">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortTestId"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        {configJSON.snoTxt}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left" className="tabHeaderCell"
                  >
                    <TableSortLabel
                      data-test-id="requestSortStudentTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        App ID
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortappTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        Status
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tabHeaderCell" align="center">
                    <TableSortLabel
                      data-test-id="requestSortappTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        University
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="associateSortTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        Program
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortCreateTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        Start Date
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right" className="tabHeaderCell">
                    <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.ApplicationDataList.map((data, index) => {
                  let ChipColorClass = "";
                  let ChipText = "";
                  if (data.ApplicationStatus === "in_progress") {
                    ChipColorClass = "InprogressClass"
                    ChipText = "In Progress"
                  }
                  else if (data.ApplicationStatus === "refund") {
                    ChipColorClass = "RefundClass"
                    ChipText = "refund"
                  }
                  else if (data.ApplicationStatus === "enrolled") {
                    ChipColorClass = "EnrolledClass"
                    ChipText = "Enrolled"
                  }
                  else if (data.ApplicationStatus === "discontinued") {
                    ChipColorClass = "DiscountinuedClass"
                    ChipText = "Discountinued"
                  }
                  let StperCount;
                  let SterperJsk;
                  if (data.ApplicationStatus === "in_progress") {
                    StperCount = this.SuccessStperCount(data.ApplicationStageForInprogress)
                    SterperJsk = this.SuccessStepper(StperCount)
                  } else if (data.ApplicationStatus === "enrolled") {
                    SterperJsk = this.SuccessStepper(Object.keys(data.ApplicationStageForInprogress).length)
                  } else if (data.ApplicationStatus === "refund") {
                    StperCount = this.RefundStperCount(data.ApplicationStageForRefund)
                    SterperJsk = this.RefundStepper(StperCount, data.ApplicationStageForRefund)
                  } else if (data.ApplicationStatus === "discontinued") {
                    SterperJsk = this.DiscountinueTableCell(data.discontinued_reason)
                  }
                  return (
                    <React.Fragment key={data.id}>
                      <TableRow className="RowContainer">
                        <TableCell>
                          <Typography gutterBottom variant="h6" className="SmallText">
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom variant="h6" className="SmallText">
                            {data.ApplicationId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Chip label={ChipText} className={ChipColorClass} />
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom variant="h6" className="SmallText" align="center">
                            {data.University}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom variant="h6" className="SmallText" align="center">
                            {data.course}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography align="left" gutterBottom variant="h6" className="SmallText">
                            {data.StartDate}</Typography>
                        </TableCell>
                        <TableCell data-test-id={"ViewAppBtn" + index} align="right" className="RemovePadding ViewAppButtonCell">
                          <Button data-test-id={"ApplicationsViewAppBtn" + index} onClick={() => {
                            this.NaviGateToViewApplication(data.id, data.ApplicationId)
                          }} endIcon={<ArrowForwardIcon />} className="ViewAppButton" variant="contained">
                            View App
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow className="RowContainer" style={{ backgroundColor: "#F8FAFC", }}>
                        <TableCell colSpan={8} style={{ backgroundColor: "#F8FAFC", }}>
                          <Collapse in={true} timeout="auto" style={{ backgroundColor: "#F8FAFC", }} unmountOnExit>
                            <Box sx={{ width: '100%' }}>
                              {SterperJsk}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                      {
                        index !== this.state.ApplicationDataList.length - 1 &&
                        <TableRow>
                          <TableCell colSpan={8} style={{ height: '16px', border: 'none' }} />
                        </TableRow>
                      }
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ApplicationsStyled>
    )
  }

  DiscountinueTableCell = (reason: string) => {
    const formatText = (text: string) => text ? text.replace(/_/g, ' ') : text;
  
    return (
      <Box className="DiscountinuedContainer">
        <Box>
          <Typography variant="h6" className="ReasonText">
            Reason
          </Typography>
        </Box>
        <Box className="TextAndArrowContainer">
          <Box>
            <ArrowForwardIcon />
          </Box>
          <Box>
            <Typography variant="h6" className="AgentConfirmText">
              {formatText(reason)}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  
  SuccessStepper = (StepCount: number) => {
    return (
      <Box className="PipelineContainer">
        <Stepper
          alternativeLabel
          activeStep={StepCount}
          className="StperClass"
          connector={<SuccessColorlibConnector />}
        >
          {this.StperData().map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={SuccessColorlibStepIcon}>
                <Typography variant="h6" className="SteperHeading">
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }


  RefundStepper = (StepCount: number, data: {
    refund_initiated?: boolean,
    refund_received?: boolean,
    refund_rejected?: boolean,
    refund_submitted_to_institution?: boolean
  }) => {
    const { refund_received, refund_rejected } = data
    let StperData = [...this.SecondStperData()]
    if (!refund_received && !refund_rejected) {
      StperData.push("Refund Received")
    }
    if (refund_received) {
      StperData.push("Refund Received")
    }
    if (refund_rejected) {
      StperData.push("Refund Rejected")
    }
    return (
      <Box className="PipelineContainer">
        <Stepper
          alternativeLabel
          activeStep={StepCount}
          className="StperClass RefundStperClass"
          connector={<RefundlibConnector />}
        >
          {StperData.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={RefundColorlibStepIcon}>
                <Typography variant="h6" className="SteperHeading RefundSteperHeading">
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }

  TransactionsDataRender = () => {
    return (
      <Box className="TabDetailsContainer" style={{ height: "auto" }} />
    )
  }



  AssignedStaffTabRender = () => {
    const { VisionStaffData, UniversityStaff, AgentStaff } = this.state
    return (
      <AssignedStaffTabStyle className="TabDetailsContainer" style={{ backgroundColor: "#fff" }}>
        <Box className="AssignedStaffContainer">
          <Box className="VisitonStaffContainer">
            <Box className="HeadingContainer">
              <Typography gutterBottom variant="h6" className="AssignedStaffHeading" style={{paddingBottom:"20px"}}>
                Vision International Staff
              </Typography>
            </Box>
            {VisionStaffData ?
            <Box>
              <Grid container spacing={6}>
                {
                  VisionStaffData?.map((data) => {
                    return (
                      <Grid item xs={12} md={3} key={data.id}>
                        <Card className="CardOfAssignedStaff">
                          <Box className="StaffLogoContainer" >
                            <img className="StaffLogo" src={data.image === "" ? defaultImg : data.image} />
                          </Box>
                          <Box className="FirstNameLastNameContainer">
                            <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
                              {data.FirstName}{" "}{data.LastName}
                            </Typography>
                          </Box>
                          <Box className="TiltleContainer">
                            <Typography align="center" gutterBottom variant="h6" className="SatffSubHeading">
                              {data.Role}
                            </Typography>
                          </Box>
                          <Box className="PhoneContainer">
                            <Typography align="center" gutterBottom variant="h6" className="SatffSubHeading  phonerNumber">
                              <img src={CalliconforAssign} />
                              <a href={`tel:${data.phone_number}`}>
                                &#x2B; {data.phone_number}
                              </a>
                            </Typography>
                          </Box>
                        </Card>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Box>
            : <Box className="HeadingContainer">
            <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
              Staff Data Not Available
            </Typography>
          </Box>}
          </Box>
          <Box className="UniversityStaffContainer VisitonStaffContainer">
            <Box className="HeadingContainer">
              <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                University Staff
              </Typography>
            </Box>

            {Array.isArray(UniversityStaff) && UniversityStaff.length !== 0 ?
              <Box>
                <Grid container spacing={6}>
                  {
                    UniversityStaff.map((data) => {
                      return (
                        <Grid item xs={12} md={3} key={data.id}>
                          <Card className="CardOfAssignedStaff">
                            <Box className="StaffLogoContainer" >
                              <img className="StaffLogo" src={data.image === "" ? defaultImg : data.image} />
                            </Box>
                            <Box className="FirstNameLastNameContainer">
                              <Typography align="center" gutterBottom variant="h6" className="AssignedStaffHeading">
                                {data.FirstName}{" "}{data.LastName}
                              </Typography>
                            </Box>
                            <Box className="TiltleContainer">
                              <Typography align="center" gutterBottom variant="h6" className="SatffSubHeading">
                                {data.role}
                              </Typography>
                            </Box>
                            <Box className="PhoneContainer">
                              <Typography align="center" gutterBottom variant="h6" className="SatffSubHeading  phonerNumber">
                                <img src={CalliconforAssign} />
                                <a href={`tel:${data.PhoneNumber}`}>
                                  &#x2B; {data.PhoneNumber}
                                </a>
                              </Typography>
                            </Box>
                          </Card>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Box>
              :
              <Box className="HeadingContainer">
                <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                  Data Not Available
                </Typography>
              </Box>
            }
          </Box>
          <Box>
            <Box className="UniversityStaffContainer VisitonStaffContainer">
              <Box className="HeadingContainer">
                <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                  Agent Staff
                </Typography>
              </Box>
            </Box>
            <TableContainer component={Paper} className="TableContaier ScrollTableContaier">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className="tabHeaderCell positionStickyLeft">
                      <TableSortLabel
                        data-test-id="requestSortTestId"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                          {configJSON.snoTxt}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" className="tabHeaderCell">
                      <TableSortLabel
                        data-test-id="requestSortStudentTestID"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                          App ID
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" className="tabHeaderCell">
                      <TableSortLabel
                        data-test-id="requestSortappTestID"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                          Status
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className="tabHeaderCell">
                      <TableSortLabel
                        data-test-id="requestSortappTestID"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                          University
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className="tabHeaderCell">
                      <TableSortLabel
                        data-test-id="associateSortTestID"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                          Program
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center"
                      style={{
                        whiteSpace: "nowrap"
                      }} className="tabHeaderCell">
                      <TableSortLabel
                        data-test-id="requestSortCreateTestID"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                          Start Date
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right" className="tabHeaderCell">
                      <Typography gutterBottom variant="h6" className="AssignedStaffHeading">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    AgentStaff?.length !== 0 ? AgentStaff?.map((data, index) => {
                      let ChipColorClass = "";
                      let ChipText = "";
                      if (data.application_status === "in_progress") {
                        ChipColorClass = "InprogressClass"
                        ChipText = "In Progress"
                      }
                      else if (data.application_status === "refund") {
                        ChipColorClass = "RefundClass"
                        ChipText = "refund"
                      }
                      else if (data.application_status === "enrolled") {
                        ChipColorClass = "EnrolledClass"
                        ChipText = "Enrolled"
                      }
                      else if (data.application_status === "discontinued") {
                        ChipColorClass = "DiscountinuedClass"
                        ChipText = "Discountinued"
                      }
                      return (
                        <React.Fragment key={data.student_id + index}>
                          <TableRow className="RowContainer">
                            <TableCell>
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              <Typography gutterBottom variant="h6" className="SmallText">
                                {data.application_id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Chip label={ChipText} className={ChipColorClass} />
                            </TableCell>
                            <TableCell>
                              <Typography gutterBottom variant="h6" className="SmallText" align="center">
                                {data.university}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography gutterBottom variant="h6" className="SmallText" align="center">
                                {data.course}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography align="left" gutterBottom variant="h6" className="SmallText">
                                {data.start_date}</Typography>
                            </TableCell>
                            <TableCell data-test-id={"ViewAppBtn" + index} align="right" className="RemovePadding ViewAppButtonCell">
                              <Button data-test-id={"AssigneAppViewViewAppBtn" + index} onClick={() => {
                                this.NaviGateToViewApplication(data.id, data.application_id)
                              }} endIcon={<ArrowForwardIcon />} className="ViewAppButton" variant="contained">
                                View App
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow className="RowContainer" style={{ backgroundColor: "#f8fafc" }}>
                            <TableCell colSpan={7}>
                              <Box>
                                <Grid container>
                                  <Grid item xs={8}>
                                    <Grid container>
                                      <Grid item xs={1}>
                                        <Box className="TableStudentContainer">
                                          <img className="TableImage" src={defaultImg} />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={10} >
                                        <Box className="NameAndDetailsContainer">
                                          <Grid container alignItems="center">
                                            <Grid item xs={2}>
                                              Name:
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Typography variant="h6" className="AssignedStaffHeading">
                                                {data.FirstName}{' '}{data.LastName}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Grid container alignItems="center">
                                            <Grid item xs={2}>
                                              Designation:
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Typography variant="h6" className="AssignedStaffHeading">
                                                {data.Designation}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Grid container alignItems="center">
                                            <Grid item xs={2}>
                                              Mobile:
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Typography variant="h6" className="AssignedStaffHeading">
                                                +{data.CountryCode}-{data.PhoneNumber}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Box className="ReassignapplicationBtnContainer">
                                      <Button onClick={() => {
                                        this.OpenReAssigneModel(data.agent_id)
                                      }} data-test-id={"ReassignBtn"} className="ReassignapplicationBtn">
                                        Reassign application
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </TableCell>
                          </TableRow>
                          {
                            index !== AgentStaff.length - 1 &&
                            <TableRow>
                              <TableCell colSpan={7} style={{ height: '16px', border: 'none' }} />
                            </TableRow>
                          }
                        </React.Fragment>
                      )
                    }) :
                      <TableRow>
                        <TableCell colSpan={7} className="NoDataAvailable">
                          Data Not Available
                        </TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {this.ReAssigneModel()}
      </AssignedStaffTabStyle>
    )
  }

  ReAssigneModel = () => {
    const { ReAssigneModelState, associateValue, StundetData } = this.state
    return (
      <Modal onClose={this.OnCloseReAssigneModelState} open={ReAssigneModelState}
        data-test-id="filterModal">
        <ModalContent>
          <BoxPopupClose>
            <Close onClick={this.OnCloseReAssigneModelState} data-test-id="OnCloseReAssigneModelState" />
          </BoxPopupClose>
          <ModaInnerBox >
            <Box style={{ width: "100%" }}>
              <BoxPopUpTitle className="filterTitle">
                <FilterModalTitle style={{ fontWeight: 700 }}>Reassign application</FilterModalTitle>
                <FilterModalSubTitle style={{ fontFamily: "Plus Jakarta Sans", fontWeight: 400 }} >Choose member</FilterModalSubTitle>
              </BoxPopUpTitle>
            </Box>
            <Box className="insideBox">
              <Box className="innerContent">
                <Box style={{ marginTop: "16px" }}>
                  <Box style={{ display: "flex", gap: "16px" }}>
                    <Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Student Name:- </Typography>
                    <Typography>{StundetData.first_name + " " + StundetData.last_name}</Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: "16px" }}>
                    <Box style={{ display: "flex" }}><Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Student ID:-  </Typography>
                      <Typography> {StundetData.student_id} </Typography></Box>
                    <Box style={{ display: "flex" }}><Typography style={{ fontWeight: "700" }} gutterBottom variant="body1">Passport No:-  </Typography>
                      <Typography> {StundetData.passport_number}</Typography></Box>
                  </Box>
                </Box>
              </Box>

              <Box className="innerContent">
                <Typography className="inputTxt">
                  Assign To
                </Typography>
                <Select
                  className="selectInput"
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                  style={{
                    color: associateValue == "none" ? "#999" : "#000"
                  }}
                  value={associateValue}
                  data-test-id="selectBranch"
                  IconComponent={KeyboardArrowDown}
                  onChange={this.handleBranchChange}
                >
                  <MenuItem value="none">
                    Select Agent/Counsellor
                  </MenuItem>
                  {this.state.ReAssigneModelData?.agents?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <MenuListItems>
                        <Box className="cardLogoImg">
                          <img
                            width="100%"
                            height="100%"
                            src={item.profile_image?.url}
                            alt={item.agent_name}
                            className="cardLogoImg"
                          />
                        </Box>
                        <Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">ID:</Typography>
                            <Typography className="listContentValue">{item.agent_id}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Name:</Typography>
                            <Typography className="listContentValue">{item.agent_name}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Designation:</Typography>
                            <Typography className="listContentValue">{item.designation}</Typography>
                          </Box>
                          <Box className="listContentBox">
                            <Typography className="listContentTitle">Email:</Typography>
                            <Typography className="listContentValue">{item.email}</Typography>
                          </Box>
                        </Box>
                      </MenuListItems>
                    </MenuItem>
                  ))}
                </Select>
                {this.state.reassignAgentError && (
                  <RequiredTxt>{this.state.reassignAgentError}</RequiredTxt>
                )}
              </Box>
            </Box>
            <StyledBoxButtonWrapper>
              <StyledApplyFilterButton
                onClick={this.reassignStudentApiCallFuncation}
                data-test-id="applyReassgine"><StyledTypoGraphyApply>Reassign application to chosen member</StyledTypoGraphyApply></StyledApplyFilterButton>
            </StyledBoxButtonWrapper>
          </ModaInnerBox>
        </ModalContent>
      </Modal>
    )
  }

  ShortListButton = () => {
    const { ShortListApiResponseData, ActiveTabIndex } = this.state
    return (
      ActiveTabIndex === 2 && ShortListApiResponseData.length !== 0 &&
      <Button data-test-id="ShortListButton" onClick={() => {
        this.NaviGatToCorseFidner()
      }} className="DownloadDocumentsButton">
        Shortlist Now
      </Button>
    )
  }


  getIconForFileType = (fileName: string) => {
    if (fileName?.includes('pdf')) {
      return <PictureAsPdf style={{ color: '#000000' }} />
    };
    if (fileName?.includes('png') || fileName?.includes('jpg')) {
      return <Image style={{ color: '#000000' }} />
    }
    if (fileName?.includes('doc') || fileName?.includes('docx')) {
      return <Description style={{ color: '#000000' }} />;
    }
  };

  SendRequestModel = () => {
    const { StundetData } = this.state
    return this.state.AskForExpertModel &&
      <SentRequest isAddRequestPopup={this.state.AskForExpertModel} handleAddRequestPopup={this.HandelOpenAskForExpert} navigation={undefined} id={""}
        defaultStudent={
          {
            student_id: StundetData.student_id,
            first_name: StundetData.first_name,
            passport_number: StundetData.passport_number
          }
        }
      />

  }


  ShortListDataRender = () => {
    const { ShortListApiResponseData } = this.state
    return (
      <ShortListStyle className="TabDetailsContainer ShortListDataRenderClass">
        {
          ShortListApiResponseData.length === 0 ?
            <Box className="NoDataSectionClass">
              <Box className="IconDetailsContainer">
                <Box className="width40">
                  <Box className="IconContainer">
                    <Box className="Icon" >
                      <DescriptionIcon />
                    </Box>
                  </Box>
                  <Box>
                    {this.SendRequestModel()}
                    <Typography align="center" gutterBottom variant="h6" style={{ textTransform: "none" }} className="ShortListHeadig Nocourses">
                      No courses shortlisted yet
                    </Typography>
                    <Typography gutterBottom variant="h6" className="ShortListSubHeadig" style={{ textTransform: "none" }}>
                      Student do not have any shortlisted courses yet.
                    </Typography>
                    {
                      this.state.NoShorListMessage !== "" &&
                      <Typography gutterBottom variant="h6" className="ShortListSubHeadig" style={{ textTransform: "none" }}>
                        {this.state.NoShorListMessage}
                      </Typography>
                    }
                  </Box>
                  <Box>
                    <Box className="ButtonContainer">
                      <Button disabled={this.state.NoShorListMessage !== ""} data-test-id="HandelOpenAskForExpert" onClick={this.HandelOpenAskForExpert} startIcon={<this.TipsIcon />} fullWidth className={`AskExpertBtn  ${this.state.NoShorListMessage !== "" ? "DisableAskExpertBtn" : ""}`}>
                        Ask for the Expert
                      </Button>
                      <Button data-test-id="NaviGatToCorseFidner" fullWidth onClick={() => {
                        this.NaviGatToCorseFidner()
                      }} className="AskExpertBtn ShortlistBtn">
                        Shortlist Now
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box> :
            ShortListApiResponseData.map((items, index) => {
              return (
                <Box className="ShortListDataRender" key={items.Id + index}>
                  <Box className="InlinePadingClass">
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        <Box className="LogoAndTextContainer">
                          <Box className="LogoContainer" >
                            <img src={items.UniversityLogo} className="UniversityLogo" />
                          </Box>
                          <Box className="UniversityTextContainer" >
                            <Box>
                              <Typography align="left" gutterBottom variant="h6" className="ShortListHeadig Nocourses">
                                {items.ProgramName}
                              </Typography>
                            </Box>
                            <Box className="UniversityAndLocationContainer">
                              <Box className="UniversityIconAndText">
                                <Box className="SmallIcon">
                                  <img src={CourtHouseIcon} />
                                </Box>
                                <Typography gutterBottom variant="h6" className="ShortListSubHeadig">
                                  {items.UniversityName}
                                </Typography>
                              </Box>
                              <Box className="UniversityIconAndText">
                                <Box className="SmallIcon">
                                  <img src={LocationIcon} />
                                </Box>
                                <Typography gutterBottom variant="h6" className="ShortListSubHeadig">
                                  {items.UniversityLocation}, {' '}{items.UniversityCountry}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box className="ApplyNoeContainer">
                          <Button data-test-id={"ApplyNowButtonOfShortlistTab" + index} onClick={() => { this.ApplyNowButtonOfShortlistTab(items.Id) }} className="AskExpertBtn ShortlistBtn">
                            Apply Now
                          </Button>
                          <Button data-test-id={"ApplyNowButtonOfDeleteTab" + index} onClick={() => this.handleDeleteSHortlist(items.Id)}>
                            <DeleteIcon style={{ color: "red", }} />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="SecondContainer">
                      <Grid container spacing={2}>
                        {items.Sessions.map((Sessionitems, index) => {
                          const IntakeLabelData = Sessionitems.IntakeLabel.split(' ')
                          const IntakeLabel = `${IntakeLabelData[0]} ${IntakeLabelData[1].substring(0, 3)} ${IntakeLabelData[2]}`
                          let ColorChangeClassName: string = "";
                          if (Sessionitems.Availability.toLowerCase() === "to be opened") {
                            ColorChangeClassName = "ToBeOpenedClass"
                          }
                          if (Sessionitems.Availability.toLowerCase() === "closed") {
                            ColorChangeClassName = "closed"
                          }
                          return (
                            <Grid item md={4} xs={12} key={Sessionitems.IntakeLabel + index}>
                              <Box className="IntakeLabelContainer">
                                <Typography align="center" gutterBottom variant="h6" className="ShortListHeadig">
                                  {IntakeLabel}
                                </Typography>
                                <Button variant="outlined" className={"IntakeLabelButton" + "  " + ColorChangeClassName}>
                                  {Sessionitems.Availability}
                                </Button>
                              </Box>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                    <Box className="ThirdContainer">
                      <Box className="TextDetailsContainer">
                        <Typography gutterBottom variant="h6" className="DetailsHeading">
                          FEES after Scholarship (Approx)
                        </Typography>
                        <Typography gutterBottom variant="h6" className="DetailsSubHeading">
                          {items.FeesAfterScholarship}
                        </Typography>
                      </Box>
                      <Box className="TextDetailsContainer">
                        <Typography gutterBottom variant="h6" className="DetailsHeading">
                          cas deposit (Approx)
                        </Typography>
                        <Typography gutterBottom variant="h6" className="DetailsSubHeading">
                          {items.CashDeposit}
                        </Typography>
                      </Box>
                      <Box className="TextDetailsContainer">
                        <Typography gutterBottom variant="h6" className="DetailsHeading">
                          cas deposit (Approx)
                        </Typography>
                        <Typography gutterBottom variant="h6" className="DetailsSubHeading">
                          {items.Fees}
                        </Typography>
                      </Box>
                      <Box className="TextDetailsContainer">
                        <Typography gutterBottom variant="h6" className="DetailsHeading">
                          Offer TAT
                        </Typography>
                        <Typography gutterBottom variant="h6" className="DetailsSubHeading">
                          {items.UniversityOfferTat}
                        </Typography>
                      </Box>
                      <Box className="TextDetailsContainer">
                        <Typography gutterBottom variant="h6" className="DetailsHeading">
                          DURATION
                        </Typography>
                        <Typography gutterBottom variant="h6" className="DetailsSubHeading">
                          {items.Duration}
                        </Typography>
                      </Box>
                      <Box className="TextDetailsContainer">
                        <Typography gutterBottom variant="h6" className="DetailsHeading">
                          PROGRAM FILED
                        </Typography>
                        <Typography gutterBottom variant="h6" className="DetailsSubHeading">
                          {items.ProgramLevel}
                        </Typography>
                      </Box>
                      <Box className="TextDetailsContainer">
                        <Typography gutterBottom variant="h6" className="DetailsHeading">
                          Discipline
                        </Typography>
                        <Typography gutterBottom variant="h6" className="DetailsSubHeading">
                          {items.Discipline}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })
        }
      </ShortListStyle>
    )
  }

  TipsIcon = () => {
    return (
      <img src={Tips} className="AskIcon" />
    )
  }



  VisaTravelModelRender = () => {
    const { VisaAndTravelData, VisaAndTravelTitle, IsVisaAndTravelModelOpen } = this.state
    return (
      <VisaTravelModel maxWidth='xl' onClose={this.OpenVisaAndTravelModelClose} aria-labelledby="simple-dialog" open={IsVisaAndTravelModelOpen}>
        <Box className="Container">
          <Box className="CloseIconContainer">
            <IconButton data-test-id="CloseUploadModelVisaTravel" onClick={this.OpenVisaAndTravelModelClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography gutterBottom variant="h6" className="ModalHeadig">
            {VisaAndTravelTitle}
          </Typography>
          <DialogContent>
            {
              VisaAndTravelData.map((items, index) => {
                return (
                  <Box key={items.fullForm + index}>
                    <Box className="ContantryAndDateClass">
                      <Typography variant="body1" className="Title LightcolorText">
                        Name :
                      </Typography>
                      <Typography variant="body1" className="Title BoldTitle">
                        {items.fullForm}
                      </Typography>
                    </Box>
                    <Box className="ContantryAndDateClass">
                      <Typography variant="body1" className="Title LightcolorText">
                        Date :
                      </Typography>
                      <Typography variant="body1" className="Title BoldTitle"> {moment(items.date).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                )
              })
            }
          </DialogContent>
        </Box>
      </VisaTravelModel>
    )
  }

  StudentDoucmentRedner = () => {
    const DoumentData = this.FilterDocumentData("Education Documents")
    const EnglishTestScoreData = this.FilterDocumentData("English Test Score")
    const WorkExperienceData = this.FilterDocumentData("Work Experience")
    const PassportTravelData = this.FilterDocumentData("Passport Travel")
    const OtherDocumentsData = this.OtherDocumentsData()
    const LengthDoumentData = DoumentData.length >= 5
    const LengthEnglishTest = EnglishTestScoreData.length >= 5
    const LengthWorkExperienceTest = WorkExperienceData.length >= 5
    const LengthPassportTravelTest = PassportTravelData.length >= 5
    const LengthOtherDocuments = OtherDocumentsData.length >= 5

    return (
      <Box className="TabDetailsContainer">
        {this.VisaTravelModelRender()}
        <Box className="Container">
          <Typography variant="h6" className="StudentDetailsHeadig">
            Education Detail
          </Typography>
          <Grid container spacing={4} >
            {DoumentData.map((items, index: number) => {
              return (
                <Grid item xs={12} lg={3} md={4} key={items.Id + '-' + items.DocumentCategory}>
                  <Card className="DoucmentCard">
                    <Box className="IconContainer" >
                      <IconButton aria-label="delete">
                        {this.getIconForFileType(items?.FileType?.split('/')[1]) ?? ""}
                      </IconButton>
                      {this.getStatusIcon(items.Status)}
                      {items.Status !== 'done' && (
                        <IconButton className="DeleteIconButtonClass" data-test-id={"DoumentDataDelete" + index} onClick={() => {
                          this.DeleteDoument(items.Id)
                        }}>
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </Box>
                    <Box className="DocumentHeadingContainer">
                      <Typography gutterBottom variant="subtitle1" className="DoumentHeading" noWrap>
                        {items.Name}
                      </Typography>
                      <Typography variant="subtitle1" className="DoumentSize" noWrap>
                        {items.FileSize}
                      </Typography>
                    </Box>
                    <Box className="DownloadButtonContainer">
                      <Button variant="outlined" data-test-id={"ViewDocEdution" + index} onClick={() => {
                        this.OpenDocument(items.FileUrl)
                      }} fullWidth className="DownloadButton"
                        endIcon={<FileDownload />}>
                        Download
                      </Button>
                    </Box>
                    {
                      items.Comment &&
                      <Box className="ComentClassContainer">
                        <Tooltip title={items.Comment}>
                          <InfoIcon />
                        </Tooltip>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: `${items.Comment}` }}
                          className="ComentClass" variant="subtitle1" />
                      </Box>
                    }
                  </Card>
                </Grid>
              )
            })}
            <Grid item xs={12} md={3}>
              <Card className="DoucmentCard AddDoumentCard">
                <Button disabled={LengthDoumentData} data-test-id="UploadDoumentModelOpenEducationDetails" startIcon={<AddIcon />} onClick={() => {
                  this.UploadDoumentModelOpen("Education Documents", "Education Detail", "Please attach your academics in chronological order in single file.")
                }} variant="contained"
                  component="span"
                  className="AddDocumentButton">
                  Add document
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box className="Container">
          <Typography variant="h6" className="StudentDetailsHeadig">
            English Test Score
          </Typography>
          <Grid container spacing={4} >
            {EnglishTestScoreData.map((items, index) => {
              return (
                <Grid item xs={12} lg={3} md={4} key={items.Id + '-' + items.DocumentCategory}>
                  <Card className="DoucmentCard">
                    <Box className="IconContainer" >
                      <IconButton aria-label="delete">
                        {this.getIconForFileType(items?.FileType?.split('/')[1])}
                      </IconButton>
                      {this.getStatusIcon(items.Status)}
                      {items.Status !== 'done' && (
                      <IconButton data-test-id={"DoumentDataDeleteEnglishTest" + index} className="DeleteIconButtonClass" onClick={() => {
                        this.DeleteDoument(items.Id)
                      }}>
                        <DeleteOutline />
                      </IconButton>
                      )}
                    </Box>
                    <Box className="DocumentHeadingContainer">
                      <Typography noWrap gutterBottom className="DoumentHeading" variant="subtitle1" >
                        {items.Name}
                      </Typography>
                      <Typography noWrap className="DoumentSize" variant="subtitle1">
                        {items.FileSize}
                      </Typography>
                    </Box>
                    <Box className="DownloadButtonContainer">
                      <Button fullWidth data-test-id={"ViewDocEnglishTest" + index} onClick={() => {
                        this.OpenDocument(items.FileUrl)
                      }} className="DownloadButton"
                        variant="outlined" endIcon={<FileDownload />}>
                        Download
                      </Button>
                    </Box>
                    {
                      items.Comment &&
                      <Box className="ComentClassContainer">
                        <Typography
                          dangerouslySetInnerHTML={{ __html: `${items.Comment}` }}
                          className="ComentClass" variant="subtitle1" />
                      </Box>
                    }
                  </Card>
                </Grid>
              )
            })
            }
            <Grid item xs={12} md={3}>
              <Card className="DoucmentCard AddDoumentCard">
                <Button disabled={LengthEnglishTest} data-test-id="UploadDoumentModelOpenEnglishTestScore" startIcon={<AddIcon />} onClick={() => {
                  this.UploadDoumentModelOpen("English Test Score", "English Test Score", "IELTS/ PTE/ MOI/ 12th transcript (if taking waiver on 12th english marks ) is required to be added.")
                }} component="span" variant="contained" className="AddDocumentButton">
                  Add document
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box className="Container">
          <Typography variant="h6" className="StudentDetailsHeadig">
            Work Experience
          </Typography>
          <Grid container spacing={4} >
            {WorkExperienceData.map((items, index: number) => {
              return (
                <Grid item xs={12} lg={3} md={4} key={items.Id + '-' + items.DocumentCategory}>
                  <Card className="DoucmentCard">
                    <Box className="IconContainer" >
                      <IconButton aria-label="delete" name="WorkExperienceData">
                        {this.getIconForFileType(items?.FileType?.split('/')[1])}
                      </IconButton>
                      {this.getStatusIcon(items.Status)}
                      {items.Status !== 'done' && (
                      <IconButton className="DeleteIconButtonClass" data-test-id={"WorkExperienceDataDelete" + index} onClick={() => { this.DeleteDoument(items.Id) }}>
                        <DeleteOutline />
                      </IconButton>
                      )}
                    </Box>
                    <Box className="DocumentHeadingContainer">
                      <Typography gutterBottom
                        className="DoumentHeading"
                        variant="subtitle1" noWrap>
                        {items.Name}
                      </Typography>
                      <Typography className="DoumentSize"
                        variant="subtitle1" noWrap>
                        {items.FileSize}
                      </Typography>
                    </Box>
                    <Box className="DownloadButtonContainer">
                      <Button data-test-id={"ViewWorkExperienceData" + index} endIcon={<FileDownload />} fullWidth onClick={() => {
                        this.OpenDocument(items.FileUrl)
                      }} className="DownloadButton" variant="outlined">
                        Download
                      </Button>
                    </Box>
                    {
                      items.Comment &&
                      <Box className="ComentClassContainer">
                        <Typography
                          dangerouslySetInnerHTML={{ __html: `${items.Comment}` }}
                          className="ComentClass" variant="subtitle1" />
                      </Box>
                    }
                  </Card>
                </Grid>
              )
            })
            }
            <Grid item xs={12} md={3}>
              <Card className="DoucmentCard AddDoumentCard">
                <Button disabled={LengthWorkExperienceTest} data-test-id="UploadDoumentModelOpenWorkExperince" component="span" onClick={() => {
                  this.UploadDoumentModelOpen("Work Experience", "Work Experience", "")
                }} startIcon={<AddIcon />} variant="contained" className="AddDocumentButton">
                  Add document
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box className="Container">
          <Typography variant="h6" className="StudentDetailsHeadig">
            Passport & Travel History
          </Typography>
          <Grid container spacing={4} >
            {PassportTravelData.map((items, index: number) => {
              return (
                <Grid
                  item
                  xs={12}
                  lg={3} md={4}
                  key={items.Id + '-' + items.DocumentCategory}>
                  <Card className="DoucmentCard">
                    <Box className="IconContainer" >
                      <IconButton aria-label="delete">{this.getIconForFileType(items?.FileType?.split('/')[1])}</IconButton>
                      {this.getStatusIcon(items.Status)}
                      {items.Status !== 'done' && (
                      <IconButton
                        className="DeleteIconButtonClass"
                        data-test-id={"PassportDeleteDoc" + index}
                        onClick={() => { this.DeleteDoument(items.Id) }}>
                        <DeleteOutline />
                      </IconButton>
                      )}
                    </Box>
                    <Box className="DocumentHeadingContainer">
                      <Typography gutterBottom className="DoumentHeading"
                        variant="subtitle1" noWrap>
                        {items.Name}
                      </Typography>
                      <Typography className="DoumentSize"
                        variant="subtitle1" noWrap>
                        {items.FileSize}
                      </Typography>
                    </Box>
                    <Box data-test-id={"PassportViewDoc" + index} className="DownloadButtonContainer">
                      <Button onClick={() => {
                        this.OpenDocument(items.FileUrl)
                      }} fullWidth className="DownloadButton" variant="outlined" endIcon={<FileDownload />}>
                        Download
                      </Button>
                    </Box>
                    {
                      items.Comment &&
                      <Box className="ComentClassContainer">
                        <Typography
                          dangerouslySetInnerHTML={{ __html: `${items.Comment}` }}
                          className="ComentClass" variant="subtitle1" />
                      </Box>
                    }
                  </Card>
                </Grid>
              )
            })
            }
            <Grid item xs={12} md={3}>
              <Card className="DoucmentCard AddDoumentCard">
                <Button disabled={LengthPassportTravelTest} data-test-id="UploadDoumentModelOpenPassport&TravelHistory" onClick={() => {
                  this.UploadDoumentModelOpen("Passport Travel", "Passport & Travel History", "Enter/edit Visa refusal information or travel history in Personal information section. ")
                }} startIcon={<AddIcon />} component="span" variant="contained" className="AddDocumentButton">
                  Add document
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box className="Container">
          <Typography variant="h6" className="StudentDetailsHeadig">
            Other Documents
          </Typography>
          <Grid container spacing={4} >
            {OtherDocumentsData.map((items) => {
              return (
                <Grid item lg={3} md={4} xs={12} key={items.Id + '-' + items.DocumentCategory}>
                  <Card className="DoucmentCard">
                    <Box className="IconContainer" >
                      <IconButton aria-label="delete">
                        {this.getIconForFileType(items?.FileType?.split('/')[1])}
                      </IconButton>
                      {this.getStatusIcon(items.Status)}
                      {items.Status !== 'done' && (
                      <IconButton
                        className="DeleteIconButtonClass"
                        onClick={() => {
                          this.DeleteDoument(items.Id)
                        }}>
                        <DeleteOutline />
                      </IconButton>
                      )}
                    </Box>
                    <Box className="DocumentHeadingContainer">
                      <Typography variant="subtitle1" noWrap gutterBottom className="DoumentHeading">
                        {items.Name}
                      </Typography>
                      <Typography className="DoumentSize" variant="subtitle1" noWrap>
                        {items.FileSize}
                      </Typography>
                    </Box>
                    <Box className="DownloadButtonContainer">
                      <Button fullWidth onClick={() => {
                        this.OpenDocument(items.FileUrl)
                      }} variant="outlined" endIcon={<FileDownload />} className="DownloadButton">
                        Download
                      </Button>
                    </Box>
                    {
                      items.Comment &&
                      <Box className="ComentClassContainer">
                        <Typography
                          dangerouslySetInnerHTML={{ __html: `${items.Comment}` }}
                          className="ComentClass" variant="subtitle1" />
                      </Box>
                    }
                  </Card>
                </Grid>
              )
            })
            }
            <Grid item xs={12} md={3}>
              <Card className="DoucmentCard AddDoumentCard">
                <Button disabled={LengthOtherDocuments} data-test-id="UploadDoumentModelOpenOtherDocuments" onClick={() => {
                  this.UploadDoumentModelOpen("Other Documents", "Other Documents", "LOR/ any other relevant documents to be added.")
                }} component="span" variant="contained" startIcon={<AddIcon />} className="AddDocumentButton">
                  Add document
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  RenderModel = () => {
    const { UploadDocumentAPIData, UploadFIle, FileSize } = this.state

    const UploadFileLenghIsZero = UploadFIle.length === 0
    const IsOtherDoucment = this.state.UploadDocumentAPIData.APIKey === "Other Documents"
    return (
      <UploadModalBox maxWidth='lg' onClose={this.CloseUploadModel} aria-labelledby="simple-dialog-title" open={this.state.IsUploadDoumentModal}>
        <Box className="CloseIconContainer">
          <IconButton data-test-id="CloseUploadModel" onClick={this.CloseUploadModel}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography gutterBottom variant="h6" className="ModalHeadig">
          Add document to {UploadDocumentAPIData.Title}
        </Typography>
        <DialogContent>
          {UploadDocumentAPIData.SubTitlt !== "" &&
            <Box className="HeadingContainer">
              <Box>
                <Typography variant="h6" className="ModalSubHeadig">
                  {UploadDocumentAPIData.SubTitlt}
                </Typography>
              </Box>
            </Box>
          }
          <Box className="FileFormatAndFileSizeContainer">
            <Typography gutterBottom variant="h6" className="FileFormatHeading">
              Accepted file format: <span className="FilesName" >.png, .jpg, .pdf ,.doc/.docx</span>
            </Typography>
            <Typography gutterBottom variant="h6" className="FileFormatHeading">
              Accepted max file size: <span className="FilesName" >10MB</span>
            </Typography>
          </Box>
          {UploadFileLenghIsZero ?
            <Box component="label" style={{ display: `${!UploadFileLenghIsZero ? "none" : ''}` }} data-test-id="UploadAddDoumentButton" className={`DragAndDropContainer`}>
              <Box>
                <Box className={"DragAndDropIconContainer"}>
                  <img src={DragAndDrop} className="DragAndDropIcon" /> :
                </Box>
                <Box>
                  <Typography variant="h6" className="DragAndDropheading">
                    Drag and Drop files
                  </Typography>
                  <Typography variant="h6" className="DragAndDropheading">
                    or
                  </Typography>
                </Box>
                <Box className="UploadButtonContainer">
                  <input
                    type="file"
                    data-test-id="UploadAddDoumentInput"
                    onChange={(event) => {
                      this.UploadFileMultipleFiles(event)
                    }}
                    multiple={this.state.UploadDocumentAPIData.APIKey === "Other Documents"}
                    className="InputHidden"
                    id="UploadAddDoumentCard"
                  />
                  <Button component="span" variant="contained" className="AddDocumentButton">
                    Choose a file to upload
                  </Button>
                </Box>
              </Box>
            </Box> :

            UploadFIle.map((items, index) => {
              return (
                <>
                  <Box className={`DragAndDropContainer  "BgChange"`}>
                    <Box>
                      <Box className={"DragAndDropIconContainer"}>
                        <Box>
                          <Box className="RenderIcon">
                            {this.getIconForFileType(items?.name.split(".")[1])}
                          </Box>
                          <Box>
                            <Typography variant="h6" className="DragAndDropheading" style={{ color: "#0F172A" }}>
                              {items?.name}
                            </Typography>
                            <Typography variant="h6" className="DragAndDropheading">
                              {FileSize[index]}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box className="UploadButtonContainer">
                          <Button startIcon={<DeleteForever color="error" />}
                            onClick={() => { this.OnDeleteUpload(index) }}
                            component="span" variant="contained" data-test-id={"DeleteDocBtn" + index} className="AddDocumentButton DeleteButton">
                            Delete file uploaded
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Typography gutterBottom variant="h6" className="RemarkHeading" color="error">
                    {this.state.UploadErrorText[index]}
                  </Typography>
                  {IsOtherDoucment && index === UploadFIle.length - 1 &&
                    <Box component="label" data-test-id="UploadAddDoumentButton" className={`DragAndDropContainer`}>
                      <Box>
                        <Box className={"DragAndDropIconContainer"}>
                          <img src={DragAndDrop} className="DragAndDropIcon" /> :
                        </Box>
                        <Box>
                          <Typography variant="h6" className="DragAndDropheading">
                            Drag and Drop files
                          </Typography>
                          <Typography variant="h6" className="DragAndDropheading">
                            or
                          </Typography>
                        </Box>
                        <Box className="UploadButtonContainer">
                          <input
                            type="file"
                            data-test-id="UploadNewAddDoumentInput"
                            onChange={(event) => {
                              this.UploadFileMultipleFiles(event)
                            }}
                            multiple={this.state.UploadDocumentAPIData.APIKey === "Other Documents"}
                            className="InputHidden"
                            id="UploadAddDoumentCard"
                          />
                          <Button component="span" variant="contained" className="AddDocumentButton">
                            Choose a file to upload
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  }
                </>
              )
            })
          }
          {
            !IsOtherDoucment &&
            <Typography gutterBottom variant="h6" className="RemarkHeading" color="error">
              {this.state.UploadErrorText[0]}
            </Typography>
          }
          {
            this.state.UploadDocumentAPIData.APIKey === "Other Documents" &&
            <Box className="RemarkContainer">
              <Typography gutterBottom variant="h6" className="RemarkHeading">
                Remarks
              </Typography>
              <TextField
                placeholder={"Write something here..."}
                className="inputField"
                onChange={(e) => {
                  this.RemarkOnChange(e)
                }}
                value={this.state.RemarkText}
                name={"FirstName"}
                fullWidth
                data-test-id="RemarksTextfield"
                multiline
                minRows={8}
              />
            </Box>
          }
          <Box className='SaveChagesButtonContainer'>
            <Button component="span" data-test-id="SaveButtonDocument" disabled={UploadFileLenghIsZero} variant="contained" onClick={this.OnSaveUploadDocument} className="SaveChangesButton">
              Save Changes
            </Button>
          </Box>
        </DialogContent>
      </UploadModalBox>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ViewMoreStyle>
        <Loader loading={this.state.loading} />
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
          />
          <Box className="contentBox">
            <SideMenuBar data-test-id="sidebarNavigationBtn"
              navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />
            <Box className="mainBar">
              <Box className="BoxContainer">
                <Button className="StudentIdText" data-test-id="BackLead" onClick={() => {
                  this.navigationToAnyPage("LeadManagement")
                }} startIcon={<ArrowBackIosIcon />} >
                  Student ID&nbsp;-&nbsp;<span className="BoldText">&nbsp;{this.state.StundetData.student_id}</span>
                </Button>
              </Box>
              {this.showErrorToast()}
              <Box>
                <Collapse in={true}>
                  {this.state.IsFormEdit ? this.EditStudentDetailsForm() : this.ViewStudentDetailsCard()}
                </Collapse>
                <Box>
                  <Card className="StudentDetailsContainer TabsContainer">
                    <Box className="StundetGenralDetails">
                      <Box className="ButtonAndTabsContainer">
                        <Tabs value={this.state.ActiveTabIndex}
                          indicatorColor="primary"
                          variant="scrollable"
                          textColor="primary"
                          scrollButtons="auto"
                          onChange={this.HandelTabChange}
                          className="TabsOfViewProfile"
                          data-test-id="TabsContainer"
                          aria-label="scrollable auto tabs example" >
                          <Tab label="Student Documents" />
                          <Tab label="Applications" />
                          <Tab label="Shortlisted" />
                          <Tab label="Assigned Staff" />
                          <Tab label="Transactions" />
                        </Tabs>
                        {
                          this.state.ActiveTabIndex === 0 &&
                          <Button data-test-id="DownloadAllFiles" onClick={this.DownloadAllFiles} startIcon={<GetAppIcon />} className="DownloadDocumentsButton">
                            Download Documents
                          </Button>
                        }
                        {this.ShortListButton()}
                      </Box>
                    </Box>
                  </Card>
                  <Box>
                    {this.RenderActiveTab(this.state.ActiveTabIndex)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </LeadBox>
        {this.RenderModel()}
      </ViewMoreStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ViewMoreStyle = styled(Box)({
  // main classes
  "& .HeadingEmergency": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30.24px',
  },
  "& .MobileNumberTextFieldContainer": {
    width: "100%"
  },
  "& .AddAnother": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#34D399',
    textTransform: "capitalize",
  },
  "& .selectField": {
    "& .MuiSelect-select.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)"
    },
    "& :hover": {
      borderBottom: "0px solid red"
    }
  },
  "& .ContantryAndDateClass": {
    display: "flex",
    gap: "5px",
    "@media (max-width: 1024px)": {
      gap: "0px"
    },
    "@media (max-width: 768px)": {
      gap: "5px"
    },
    alignItems: "flex-end",
    "& .Title": {
      marginTop: "2px !important",
      marginBottom: "2px !important"
    },
    "& .ReadMore": {
      marginLeft: "20px",
      cursor: "pointer",
      marginBottom: "4px"
    }
  },
  // main classes end
  "& .BoxContainer": {
    width: "100%",
    marginBottom: "14px",
    "& .StudentIdText": {
      textTransform: "capitalize",
      fontSize: "18px",
      fontFamily: 'Plus Jakarta Sans',
      color: "#64748B",
      "@media (max-width: 425px)": {
        fontSize: "12px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "16px"
      },
      "& .BoldText": {
        fontWeight: "bold",
        color: "#475569",
        textTransform: "uppercase",
      }
    }
  },
  "& .StudentDetailsContainer": {
    padding: '20px',
    "& .StundetGenralDetails": {
      "& .ViewStudentHeadig": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '26px',
        textTransform: "capitalize"
      },
      "& .ProfilePicContainer": {
        "& .StudentProfilePic": {
          widht: "96px",
          height: "96px",
          borderRadius: "50%",
          maxWidth: "96px",
          "@media (max-width: 425px)": {
            marginBottom: "10px"
          }
        }
      },
      "& .EditProfile": {
        display: "flex",
        alignItems: "center",
        "& .ProfileUpdateButton": {
          maxHeight: "30px",
          height: '30px',
          marginLeft: "20px",
          border: '1px 0px 0px 0px',
          fontWeight: '800',
          textTransform: 'capitalize',
          "@media (max-width: 425px)": {
            fontSize: '10px',
            padding: '0 2px',
          }
        },
        "& .ErrorText": {
          color: "red"
        },
      },
      "& .StudentFirstNameContainer": {
        marginLeft: "12px",
        "@media (max-width: 1024px)": {
          marginLeft: "unset"
        },
        "@media (max-width: 768px)": {
          paddingLeft: "unset"
        },
      },
      "& .DetailsCotainer": {
        "& .DetailsBox": {
          display: 'flex',
          gap: "10px",
          "& .GreyColorText": {
            color: "#64748B"
          },
          "& .MuiTypography-body1": {
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'left'
          }
        }
      },
      "& .TabsOfViewProfile": {
        padding: "10px 10px 0 10px",
        "& .MuiTabScrollButton-root.Mui-disabled": {
          display: "none"
        },
        "@media (max-width: 425px)": {
          width: "100%"
        },
        "& .MuiTabs-flexContainer": {
          paddingBottom: "10px",
          "@media (max-width: 426px)": {
            display: "grid",
            gridTemplateColumns: "auto",
            justifyContent: "left"
          },
          "& .MuiTab-wrapper":{
            "@media (max-width: 426px)": {
              alignItems: "flex-start"
            },
          }
        },
        "& .MuiTabs-indicator": {
          height: '8px',
          backgroundColor: '#34D399',
          borderRadius: '100px 100px 0px 0px',
          "@media (max-width: 425px)": {
            display: "none"
          },
        },
        "& .MuiTab-wrapper": {
          color: 'black',
          fontSize: '16px',
          fontWeight: 400,
          textTransform: 'capitalize'
        },
        "& .Mui-selected": {
          "& .MuiTab-wrapper": {
            color: 'black',
            fontWeight: 700
          },
        }
      }
    },
    "& .SecondDetailsContainer": {
      display: 'flex',
      justifyContent: 'space-between',
      "@media (max-width: 426px)": {
        display: 'block',
      },
      "& .IconAndDetailsContainer": {
        display: "flex",
        gap: '10px',
        alignItems: "center",
        marginBottom: "4px",
        "& .DarkTextColor": {
          color: "#0F172A"
        }
      },
      "& .EditButton": {
        backgroundColor: '#D6F6EB',
        border: '1px solid #34D399',
        color: '#059669',
        textTransform: 'capitalize',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '700',
        textAlign: 'center',
        padding: "5px 8px",
        borderRadius: "6px"
      },
      "& .SaveChanges": {
        color: 'white',
        backgroundColor: "#34D399"
      }
    },
    "& .EditDetailsContainer": {
      justifyContent: 'flex-end',
    }
  },
  "& .PersonalInfoContainer": {
    marginTop: "20px",
    "& .container-personal-wrap":{
      // padding:'40px',
      "@media (max-width: 760px)": {
        paddingBottom:'0px',
      },
    },
    "& .container-personal-wrap-down":{
      // padding:'40px',
      "@media (max-width: 760px)": {
        paddingTop:'0px',
      },
    },
    // textfield styles
    "& .inputField": {
      backgroundColor: "#fff",
      width: "95%",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      height: "50px",
      margin: "5px 0px",
      display: "flex",
      justifyContent: "center",
      padding: "0px 10px",
      boxSizing: "border-box",
      "& .MuiInput-root": {
        "&:focus": {
          outline: "none"
        },
        "&::before": {
          content: "none"
        },
        "&::after": {
          content: "none"
        }
      }
    },
    // textfield style end

    "& .PersonalInfoGridContainer": {
      "& .Section": {
        padding: "0 15px"
      },
      "& .removepadding": {
        "@media (max-width: 1024px)": {
          paddingRight: "unset !important",
        },
      },
      "& .DetailsContainer": {
        paddingRight: "140px",
        "@media (max-width: 425px)": {
          paddingRight: "unset !important",
        },
        "& .LightcolorText": {
          color: "#64748B"
        },
        "& .MuiGrid-item": {
          borderBottom: '1px solid #E2E8F0',
          "& .Title": {
            marginBottom: "10px",
            marginTop: "10px",
          },
          "& .BoldTitle": {
            fontWeight: "bold",
            textTransform: "capitalize"
          }
        },
        "& .NameAndDetails": {
          display: "flex",
          gap: "5px",
          alignItems: "center",
          "& .smallTitle": {
            minWidth: "70px"
          }
        }
      }
    }
  },
  "& .TabsContainer": {
    marginTop: "30px",
    padding: "0px",
    "& .ButtonAndTabsContainer": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      "@media (max-width: 768px)": {
        display: 'block',
        padding: "5px"
      },
      "@media (max-width: 426px)": {
        overflowX: "hidden",
      }
    },
    "& .DownloadDocumentsButton": {
      backgroundColor: "#373335",
      color: 'white',
      marginRight: "20px",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 700,
      textTransform: "capitalize",
      borderRadius: "6px",
      paddingLeft: "14px",
      paddingRight: "14px",
      minWidth: "230px",
      "@media (max-width: 769px)": {
        margin: "10px auto",
      },
      "@media (max-width: 426px)": {
        margin: "10px auto",
        width: "100%",
      },
    },
  },
  "& .EditForm": {
    "& .CountryDateContainer": {
      display: "flex",
      alignItems: "center",
      "& .DeleteIconContainer": {
      },
    },
    "& .PersonalInfoContainer": {
      padding: "20px"
    }
  },
  "& .RenderTab": {
    backgroundColor: "white"
  },
  "& .TabDetailsContainer": {
    backgroundColor: "#F8FAFC",
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 8px',
    padding: "20px",
    "& .Container": {
      marginBottom: "30px"
    },
    "& .InputForStudentDoc": {
      display: "none"
    },
    "& .StudentDetailsHeadig": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '26px',
      textTransform: "capitalize",
      marginBottom: "20px"
    },
    "& .AddDoumentCard": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dotted #CBD5E1 !important",
      height: "100%",
      boxShadow: "none",
      minHeight: "170px",
      padding: "0px !important",
      "& .AddDocumentButton": {
        color: "#34D399",
        border: "1px solid #34D399",
        borderRadius: "6px",
        textTransform: "capitalize",
        backgroundColor: "white",
        boxShadow: "none"
      },
      "& .Mui-disabled": {
        color: "#64748B !important",
        backgroundColor: "#F1F5F9 !important",
        border: "none !important",
      }
    },
    "& .DoucmentCard": {
      padding: '20px',
      borderRadius: '8px',
      border: '4px',
      minWidth: "220px",
      "@media (max-width: 425px)": {
        minWidth: "unset",
      },
      "& .IconContainer": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& .DeleteIconButtonClass": {
          backgroundColor: "#FEE2E2",
          color: "red"
        },
        "& .MuiButtonBase-root": {
          padding: "0px",
        }
      },
      "& .DocumentHeadingContainer": {
        marginTop: "20px",
        marginBottom: "20px"
      },
      "& .DoumentHeading": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left'
      },
      "& .DoumentSize": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        color: "#64748B"
      },
      "& .DownloadButtonContainer": {
        marginTop: "20px",
        display: "flex",
        "& .DownloadButton": {
          borderColor: "#64748B",
          color: "#64748B",
          borderRadius: "6px",
          textTransform: "capitalize"
        }
      },
      "& .ComentClassContainer": {
        display: "flex",
        marginTop: "10px",
        alignItems: "center",
        gap: "4px",
        "& .ComentClass": {
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '12px',
          fontWeight: 700,
          textAlign: 'left',
        }
      }
    }
  },
})

const ShortListStyle = styled(Box)({
  height: "auto",
  paddingRight: "0px !important",
  padding: "0px !important",
  "& .NoDataSectionClass": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"50px",
    "& .IconDetailsContainer": {
      marginTop: "100px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& .width40": {
        width: "40%",
        "@media (max-width: 768px)": {
          width: "90%",
        },
      },
      "& .IconContainer": {
        display: "flex",
        justifyContent: "center",
        "& .Icon": {
          backgroundColor: "#F8FAFC",
          height: "120px",
          width: "120px",
          borderRadius: "50px",
          "& .MuiSvgIcon-root": {
            fontSize: "100px",
            color: "lightgrey"
          }
        }
      },
      "& .ButtonContainer": {
        display: "flex",
        gap: "20px",
        marginTop: "20px",
        "@media (max-width: 768px)": {
          display: "block",
          "& button": {
            marginTop: "10px",
            marginBottom: "10px"
          }
        },
      }
    }
  },
  "& .ShortListDataRender": {
    borderBottom: "1px solid #E2E8F0",
    padding: "25px 0",
    "& .InlinePadingClass": {
      paddingInline: "25px",
    },
    "& .LogoAndTextContainer": {
      display: "flex",
      "@media (max-width: 768px)": {
        display: "block",
      },
      "& .LogoContainer": {
        "@media (max-width: 768px)": {
          display: "block",
          padding: "0 0px",
        },
        padding: "0 20px",
        "& .UniversityLogo": {
          width: '100px',
          height: '100px',
          borderRadius: "50%",
          backgroundColor: "white"
        }
      },
      "& .UniversityTextContainer": {
        "& .UniversityAndLocationContainer": {
          display: "flex",
          gap: "40px",
          "@media (max-width: 768px)": {
            display: "block",
          },
          "& .UniversityIconAndText": {
            display: "flex",
            "& .SmallIcon": {
              marginRight: "14px"
            }
          }
        }
      }
    },
    "& .ApplyNoeContainer": {
      display: "flex",
      justifyContent: "flex-end",
      gap: "8px",
      "@media (max-width: 768px)": {
        justifyContent: "flex-start",
      },
    },
    "& .SecondContainer": {
      marginTop: "30px",
      marginBottom: "30px",
      borderTop: "1px solid #B6373C",
      borderBottom: "1px solid #B6373C",
      padding: "20px",
      "& .IntakeLabelContainer": {
        display: "flex",
        alignItems: "baseline",
        gap: "20px",
        justifyContent: "center",
        "@media (max-width: 768px)": {
          justifyContent: "flex-start",
        },
      },
      "& .IntakeLabelButton": {
        backgroundColor: '#D6F6EB',
        border: '1px solid #34D399',
        color: '#059669',
        textTransform: 'capitalize',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        borderRadius: "6px",
        minWidth: "120px",
        minHeight: "32px"
      },
      "& .ToBeOpenedClass": {
        backgroundColor: "#FEF3C7",
        color: "#D97706",
        borderColor: "#D97706"
      },
      "& .ClosedClass": {
        backgroundColor: "#FFD5D5",
        color: "#DC2626",
        borderColor: "#DC2626"
      }
    },
    "& .ThirdContainer": {
      padding: "0 20px",
      display: "flex",
      gap: "10px",
      "@media (max-width: 425px)": {
        display: "block",
      },
      "@media (max-width: 768px)": {
        padding: "0 0px",
        display: "block",
      },
      "& .TextDetailsContainer": {
        padding: "5px"
      },
      "& .DetailsHeading": {
        fontFamily: 'Plus Jakarta Sans', fontSize: '12px', fontWeight: '700', lineHeight: '26px', textTransform: "uppercase",
      },
      "& .DetailsSubHeading": {
        fontFamily: 'Plus Jakarta Sans', fontSize: '16px', fontWeight: '400', lineHeight: '26px', textTransform: "capitalize",
      }
    }
  },
  "& .ShortListHeadig": {
    fontFamily: 'Plus Jakarta Sans', fontSize: '18px', fontWeight: '700', lineHeight: '26px', textTransform: "capitalize",
  },
  "& .Nocourses": {
    marginBottom: "20px"
  },
  "& .ShortListSubHeadig": {
    fontFamily: 'Plus Jakarta Sans', fontSize: '18px', fontWeight: '400', lineHeight: '26px', textTransform: "capitalize",
    textAlign: "center"
  },
  "& .AskExpertBtn": {
    backgroundColor: '#D6F6EB',
    border: '1px solid #34D399',
    color: '#059669',
    textTransform: 'capitalize',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    textAlign: 'center',
    padding: "5px 8px",
    borderRadius: "6px"
  },
  "& .DisableAskExpertBtn": {
    backgroundColor: 'lightgrey !important',
    border: '1px solid grey',
    "& .AskIcon": {
      filter: "grayscale(100%)"
    }
  },
  "& .ShortlistBtn": {
    color: 'white',
    backgroundColor: "#34D399",
    "&:hover": {
      backgroundColor: "#34D399",
      boxShadow: "none"
    },
    "& .OpenClass": {
      backgroundColor: '#D6F6EB',
      border: '1px solid #34D399',
      color: '#059669',
      textTransform: 'capitalize',
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      textAlign: 'center',
      padding: "5px 8px",
      borderRadius: "4px"
    }
  }
})

const LeadBox = styled(Box)(({ theme }) => ({
  "& .contentBox": {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridAutoFlow: "column",
      gridAutoColumns: "minmax(1px, auto)",
    },
  },
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    [theme.breakpoints.down("md")]: {
      marginLeft: "64px",
    },
    padding: "28px 50px 28px 100px",
    "@media (max-width: 600px)": {
      padding: "20px"
    },
  },
}));

function RadioButton(props: RadioProps) {
  return (
    <CustomizeRadio
      disableRipple
      color="default"
      checkedIcon={<CheckCircle />}
      {...props}
    />
  );
}

const CustomizeRadio = styled(Radio)({
  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
  {
    color: '#34D399',
    width: 24,
    height: 24
  },
  '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
    color: '#34D399',
    width: 24,
    height: 24
  },
})

const LabelsTypography = styled(Typography)({
  "&.MuiTypography-root": {
    fontWeight: '700',
  },
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Plus Jakarta Sans",
})

const StarTxt = styled("span")({
  color: "black",
  fontFamily: "Plus Jakarta Sans"
});

const SelectInput = styled(Select)({
  width: "100%",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  background: "white",
  height: "50px",
  display: "flex",
  margin: "5px 0px",
  justifyContent: "center",
  boxSizing: "border-box",
  padding: "0px 10px",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&::before": {
      content: "none"
    },
    "&:focus": {
      outline: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red !important"
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red !important"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red !important",
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red !important"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  "& .MuiInputBase-input": {
    color: '#64748B',
    fontWeight: 700,
  },

});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "15px"
});

const VisaTravelModel = styled(Dialog)({
  "& .Container": {
    minWidth: "560px",
    padding: "20px 0px",
  },
  position: "relative",
  "& .LightcolorText": {
    color: "#64748B"
  },
  "& .CloseIconContainer": {
    display: "flex",
    justifyContent: 'flex-end',
    width: "100%",
    minHeight: "40px",
    position: "absolute",
  },
  "& .BoldTitle": {
    fontWeight: "bold"
  },
  "& .ModalHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: '700',
    marginBottom: "20px",
    textAlign: "center"
  },
  "& .ContantryAndDateClass": {
    display: "flex",
    gap: "5px",
    "& .Title": {
      marginTop: "2px !important",
      marginBottom: "2px !important"
    },
    "& .ReadMore": {
      marginLeft: "20px",
      "&:hover": {
        color: "red",
        cursor: "pointer",
      }
    }
  },
})

const UploadModalBox = styled(Dialog)({
  minWidth: "560px",
  position: "relative",
  "& .CloseIconContainer": {
    display: "flex",
    justifyContent: 'flex-end',
    width: "100%",
    minHeight: "40px"
  },
  "& .HeadingContainer": {
    display: "flex",
    justifyContent: "center"
  },
  "& .MuiDialogContent-root": {
    padding: "0px 40px",
    minWidth: "600px"
  },
  "& .ModalHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: '700',
    marginBottom: "20px",
    textAlign: "center"
  },
  "& .ModalSubHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: "30px"
  },
  "& .FileFormatAndFileSizeContainer": {
    padding: "10px",
    backgroundColor: "#E2E8F0",
    borderRadius: "30px",
    margin: "0 20px",
    marginBottom: "20px",
    border: "1px solid #E2E8F0",
    "& .FileFormatHeading": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20.16px',
      textAlign: 'center',
    },
    "& .FilesName": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20.16px',
      textAlign: 'center',
    }
  },
  "& .DragAndDropContainer": {
    display: "flex",
    justifyContent: "center",
    padding: "30px",
    marginTop: "10px",
    marginBottom: "10px",
    border: "2px dotted #CBD5E1 !important",
    "& .UploadButtonContainer": {
      display: "flex",
      justifyContent: "center",
    },
    "& .DragAndDropIconContainer": {
      display: "flex",
      justifyContent: 'center',
      marginBottom: "10px"
    },
    "& .DragAndDropheading": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 400,
      textAlign: 'center'
    },
    "& .DragAndDropIcon": {
      minWidth: "60px",
      minHeight: "60px"
    },
  },
  "& .BgChange": {
    backgroundColor: "#F8FAFC",
  },
  "& .SaveChagesButtonContainer": {
    display: "flex",
    justifyContent: 'center',
    marginTop: "15px",
    paddingBottom: "30px"
  },
  "& .AddDocumentButton": {
    color: "#34D399",
    border: "1px solid #34D399",
    borderRadius: "6px",
    textTransform: "capitalize",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none"
    },
  },
  "& .DeleteButton": {
    color: "red",
    border: "1px solid #E2E8F0",
    backgroundColor: "#E2E8F0"
  },
  "& .SaveChangesButton": {
    color: "#fff",
    borderRadius: "6px",
    textTransform: "capitalize",
    boxShadow: "none",
    backgroundColor: "#34D399",
    "&:hover": {
      backgroundColor: "#34D399",
      boxShadow: "none"
    },
  },
  "& .InputHidden": {
    display: "none"
  },
  "& .RemarkContainer": {
    marginTop: "20px"
  },
  "& .RemarkHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: "capitalize",
    textAlign: "left"
  },
  "& .inputField": {
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    margin: "5px 0px",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },
  "& .RenderIcon": {
    display: "flex",
    justifyContent: 'center',
    "& .MuiSvgIcon-root": {
      fontSize: "60px"
    }
  }

});

const AssignedStaffTabStyle = styled(Box)({
  // mainclass
  "& .AssignedStaffHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: "capitalize",
  },
  "& .SatffSubHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: "capitalize",
  },
  // classes
  "& .AssignedStaffContainer": {
    padding: "20px"
  },
  "& .VisitonStaffContainer": {
    marginTop: "30px",
    "& .HeadingContainer": {
      marginBottom: "20px"
    },
    "& .CardOfAssignedStaff": {
      minWidth: '200px',
      minHeight: '200px',
      padding: '12px',
      borderRadius: '8px',
      "& .StaffLogoContainer": {
        display: "flex",
        justifyContent: 'center',
        "& .StaffLogo": {
          minWidth: "70px",
          width: "70px",
          height: "70px",
          borderRadius: "50%",


          minHeight: "70px",
        }
      },
      "& .FirstNameLastNameContainer": {
        marginTop: "10px",
        magrinBottom: "10px"
      },
      "& .TiltleContainer": {
      },
      "& .PhoneContainer": {
        "& .phonerNumber": {
          display: "flex",

          gap: "10px",

          justifyContent: 'center',

          alignItems: "center",
          "& a": {
            color: "#212121",
            textDecoration: "none"
          }
        }
      }
    }
  },
  "& .UniversityStaffContainer": {
    marginTop: "30px",
  },
  "& .ScrollTableContaier": {
    overflowY: "hidden",
    boxShadow: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      marginTop: "10px",
      height: '10px',
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: 'none',
      borderRadius: '2px',
    },
    "@media (min-width: 1024px)": {
      overflowX: "scroll",
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#CBD5E1',
      borderRadius: '2px',
    },
  },
  "& .TableContaier": {
    marginTop: "30px",
    "& .NoDataAvailable": {
      textAlign: "center"
    },
    "& .RowContainer": {
      border: "1px solid #E2E8F0",
      position: "relative",
      "& .RemoveWhiteSpace": {
        whiteSpace: "noWrap"
      },
      "& .NameAndDetailsContainer": {
        marginLeft: "10px"
      },
      "& .TableStudentContainer": {
        display: "flex",
        "& .TableImage": {
          minWidth: "70px",
          minHeight: "70px",
          width: "70px",
          height: "70px",
          borderRadius: "50%",
        }
      },
      "& .SmallText": {
        fontSize: '14px',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: '400',
        color: "#212121"
      },
      "& .InprogressClass": {
        backgroundColor: "#FEF3C7", color: "#D97706",
        textTransform: "uppercase",
      },
      "& .RefundClass": {
        backgroundColor: "#F0F8FF", color: "#0042EA",
        textTransform: "uppercase",
      },
      "& .DiscountinuedClass": {
        backgroundColor: "#F1F5F9", color: "#475569",
        textTransform: "uppercase",
      },
      "& .EnrolledClass": {
        backgroundColor: "#D1FAE5", color: "#059669",
        textTransform: "uppercase",
      },
      "& .ViewAppButtonCell": { whiteSpace: "noWrap", },
      "& .ViewAppButton": {
        color: "#34D399",
        backgroundColor: "white", border: "1px solid #34D399", borderRadius: "6px", textTransform: "capitalize",
        boxShadow: "none", marginRight: "5px"
      },
    }
  },
  "& .ReassignapplicationBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "center"
  },
  "& .ReassignapplicationBtn": {
    backgroundColor: "#373335",
    color: 'white',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "6px",
    paddingLeft: "14px",
    paddingRight: "14px",
    minHeight: "40px",
    "&:hover": {
      backgroundColor: "#373335",
    }
  }
})

const FilterModalTitle = styled(Typography)({
  color: "var(--Basic - Black, #000)",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "20px !important",
  fontStyle: "normal",
  lineHeight: '24px'
})

const FilterModalSubTitle = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px !important",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#000000",
})

const ModaInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  maxWidth: "400px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: "20px",
  boxSizing: "border-box",
  "& .filterTitle": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "var(--Basic-Black, #000)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  "& .insideBox": {
    width: "100%",
    padding: "16px 30px",
    "& .innerContent": {
      paddingTop: "16px"
    }
  },
  "& .inputTxt": {
    fontSize: "12px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#334155"
  },
  "& .selectInput": {
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "auto",
    padding: "0px 10px",
    boxSizing: "border-box",

    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "& .MuiInput-input": {
      padding: "10px"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  "& .autocompleteField": {
    "& input": {
      marginTop: "10px"
    }
  },
  "@media (min-width: 600px)": {
    width: "90%",
    maxWidth: "400px",
    padding: "30px",
    "& .filterTitle": {
      fontSize: "20px",
      lineHeight: "24px"
    },
    "& .insideBox": {
      padding: "20px 40px",
      "& .innerContent": {
        paddingTop: "20px"
      }
    },
    "& .inputTxt": {
      fontSize: "14px"
    },
  },
  "@media (min-width: 960px)": {
    width: "80%",
    maxWidth: "400px",
    padding: "40px 0",
    "& .filterTitle": {
      fontSize: "22px"
    },
    "& .insideBox": {
      padding: "20px 50px",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "16px"
    },
  },
  "@media (min-width: 1280px)": {
    width: "80%",
    maxWidth: "500px",
    padding: "50px 0",
    "& .filterTitle": {
      fontSize: "24px",
      lineHeight: "30px"
    },
    "& .insideBox": {
      padding: "50px 0",
      "& .innerContent": {
        paddingTop: "24px"
      }
    },
    "& .inputTxt": {
      fontSize: "18px"
    },
  },
});

const ApplicationsStyled = styled(Box)({
  "& .TableContaier": {
    overflowY: "hidden",
    boxShadow: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      marginTop: "10px",
      height: '10px',
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: 'none',
      borderRadius: '2px',
    },
    "@media (min-width: 1024px)": {
      overflowX: "scroll",
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#CBD5E1',
      borderRadius: '2px',
    },
  },
  "& .RemovePadding": {
    // padding: "0"
  },
  "& .RemoveWhiteSpace": {
    whiteSpace: "noWrap"
  },
  "& .ApplicationsHeading": {

    fontSize: '16px',

    fontWeight: '700',

    textTransform: "capitalize",

    fontFamily: 'Plus Jakarta Sans',
  },
  "& .ApplicationsSubHeading": {
    fontFamily: 'Plus Jakarta Sans',

    fontSize: '14px',

    fontWeight: '400',

    textTransform: "capitalize",
  },
  "& .NoDataAvailable": {
    textAlign: "center"
  },
  "& .TableDataContaoner": {
    borderBottom: "none",
  },
  "& .RowContainer": {
    border: "1px solid #E2E8F0",
    position: "relative",
    "& .RemoveWhiteSpace": {
      whiteSpace: "noWrap"
    },
    "& .SmallText": {
      fontWeight: '400',
      fontFamily: 'Plus Jakarta Sans',

      fontSize: '14px',
      color: "#212121"
    },
    "& .InprogressClass": { backgroundColor: "#FEF3C7", color: "#D97706", textTransform: "uppercase" },
    "& .RefundClass": {
      backgroundColor: "#F0F8FF", color: "#0042EA", textTransform: "uppercase",
    },
    "& .DiscountinuedClass": {
      backgroundColor: "#F1F5F9", color: "#475569", textTransform: "uppercase"
    },
    "& .EnrolledClass": {
      backgroundColor: "#D1FAE5", color: "#059669", textTransform: "uppercase",
    },
    "& .ViewAppButtonCell": {
      whiteSpace: "noWrap",
    },
    "& .ViewAppButton": {
      border: "1px solid #34D399",
      borderRadius: "6px",
      textTransform: "capitalize",
      backgroundColor: "white",
      boxShadow: "none",
      marginRight: "5px",
      color: "#34D399",
    },
    
    "& .DiscountinuedContainer": {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        fontSize: "20px"
      },
      "& .ReasonText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: '400',
        color: "#64748B"
      },
      "& .AgentConfirmText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: '400',
        color: "#000000"
      },
      "& .TextAndArrowContainer": {
        display: "flex",
        alignItems: "center",
        gap: "10px"
      }
    },
    "& .PipelineContainer": {
      backgroundColor: "#F8FAFC",
      "& .StperClass": {
        padding: "0px !important",
        backgroundColor: "transparent",
      },
      "& .RefundStperClass": {
        width: "fit-content"
      },
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        marginTop: "4px"
      },
      "& .MuiStepConnector-alternativeLabel": {
        left: "calc(-50% + 0px) !important",
        right: "calc(50% + 0px) !important"
      },
      "& .SteperHeading": {
        color: "#34D399",
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '700', textAlign: 'center',
      },
      "& .RefundSteperHeading": {
        color: "#0042EA",
      }
    }
  },
})

const SuccessColorlibConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: {
    '& $line': {
      backgroundColor: '#34D399',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#34D399',
    },
  },
  line: {
    height: 7,
    border: 0,
    backgroundColor: '#D9D9D9',
    borderRadius: 1,
  },
})(StepConnector);

const RefundlibConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: {
    '& $line': {
      backgroundColor: '#0042EA',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#0042EA',
    },
  },
  line: {
    height: 7,
    border: 0,
    backgroundColor: '#D9D9D9',
    borderRadius: 1,
  },
})(StepConnector);

function SuccessColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed || active ? (
        <CheckIcon />
      ) : (
        <div style={{ width: "10px", height: "10px", borderRadius: "50%" }} />
      )}
    </div>
  );
}
function RefundColorlibStepIcon(props: StepIconProps) {
  const classes = useRefundColorlibStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed || active ? (
        <CheckIcon />
      ) : (
        <div style={{ width: "10px", height: "10px", borderRadius: "50%" }} />
      )}
    </div>
  );
}

const useRefundColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0042EA",
  },
  active: {
    backgroundColor: "#0042EA"
  },
  completed: {
    backgroundColor: "#0042EA"
  },
  "& .MuiStepLabel-alternativeLabel": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
});



const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #34D399",
  },
  active: {
    backgroundColor: "#34D399"
  },
  completed: {
    backgroundColor: "#34D399"
  },
  "& .MuiStepLabel-alternativeLabel": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
});

const CustomTableRow = styled(TableRow)({

});

const ModalContent = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "24px",
  flexShrink: 0,
  borderRadius: "8px",
  backgroundColor: "#fff",
  width: "90%",
  maxWidth: "448px",
  height: "auto",
  minHeight: "575px",
  boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  margin: "auto",
  "@media (min-width: 600px)": {
    width: "80%",
    maxWidth: "500px",
  },
  "@media (min-width: 960px)": {
    width: "70%",
    maxWidth: "600px",
  },
  "@media (min-width: 1280px)": {
    width: "60%",
    maxWidth: "600px",
  },
});

const BoxPopupClose = styled(Box)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "10px",
  position: "absolute",
  right: "16px",
  top: "16px",
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
  "@media (min-width: 960px)": {
    right: "12px",
    top: "12px"
  }
});

const BoxPopUpTitle = styled(Box)({
  width: "94px",
  height: "32px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  "@media (min-width: 600px)": {
    width: "84px",
    height: "28px",
  },
  "@media (min-width: 960px)": {
    width: "74px",
    height: "24px",
  }
});

const MenuListItems = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  "& .cardLogoImg": {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    backgroundColor: "#F8FAFC",
    objectFit: "cover",
    display: "block"
  },
  "& .listContentBox": {
    display: "flex",
  },
  "& .listContentTitle": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A",
    minWidth: "120px"
  },
  "& .listContentValue": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A"
  }
})

const StyledBoxButtonWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  "@media (min-width: 600px)": {
    gap: "20px"
  },
  "@media (min-width: 960px)": {
    gap: "16px"
  }
});

const StyledApplyFilterButton = styled(Button)({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  backgroundColor: "#34D399 !important",
  "&:hover": {
    backgroundColor: "#34D399 !important"
  },
  "@media (min-width: 600px)": {
    padding: "8px 14px"
  },
  "@media (min-width: 960px)": {
    padding: "6px 12px"
  }
});

const StyledTypoGraphyApply = styled(Typography)({
  color: "var(--Basic-White, var(--0, #FFF))",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "capitalize"
});

// Customizable Area End