// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Country, State } from "country-state-city";
import { 
  CountryCodeObj,
  CountryInterfaceData, 
  SelectEventType
} from "../../../../packages/blocks/contactus/src/ContactusController";
import {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  createRef
} from "react";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";

export const configJSON = require("./config");

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface PageStateMap {
  [key: string]: {
    file: string;
    errorTxt: string;
    error: string;
  };
}

interface TravelType {
  country: string;
  date: string;
}
interface PersonalInformationType {
  firstName: string;
  lastName: string;
  gender: string;
  maritalStatus: string;
  email: string;
  passport:string;
  associateBranchName:string;
}

export interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  };
}

interface CountryInfo {
  alpha2: string;
  name: string;
  country_code: string;
}

interface ValidResponseType {
  message: string;
  data: UserDetails[];
  meta: {
    message: string;
  };
  countryCode: string
}

export interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success:string[]
}

export interface Props {
  navigation: any;
  id: string;
}

interface FilteredData {
  name: string;
}

interface S {
  stateValue: string;
  stateData: Array<FilteredData>;
  countryValue: string;
  countryValue_res:any;
  countryValue_nation:any;
  countryData: any;
  activeStep:number;
  cityName: string;
  educationDetailsFile:File | null;
  educationDetailsErrorTxt:string;
  educationDetailsError:boolean;
  englishTestScoreFile:File | null;
  englishTestScoreError:boolean;
  englishTestScoreErrorTxt:string;
  emergencyFile: File[];
  emergencyError:boolean;
  emergencyErrorTxt:string;
  workExperienceFile:File | null;
  workExperienceError:boolean;
  workExperienceErrorTxt:string;
  passportFile:File | null;
  passportError:boolean;
  passportErrorTxt:string;
  passportAndTravelHistory:{
    visaRefusals:TravelType[];
    travelHistories:TravelType[];
  };
  contactNumber: string;
  toggleDrawer: boolean;
  emergencyAndOtherDocument:{
    emergencyPersonName:string;
    emergencyContact:string;
    emergencyEmail:string;
    remarks:string;
  };
  showError:boolean;
  stateErrorTxt: string;
  countryErrorTxt: string;
  contactNumberError: boolean;
  contactNumberErrorTxt: string;
  contactEmerNumberErrorTxt: string;
  countryError: boolean;
  countryResErrorTxt: string;
  countryResError: boolean;
  destination: {
    destination_latitude: string;
    destination_longitude: string;
  } | null;
  cutomerData: { [keyVal: string]: string | number };
  customerID: string;
  stateError: boolean;
  cityNameError: boolean;
  cityNameErrorTxt: string;
  successPopUp:boolean;
  emergencyNumberError: boolean;
  userToken:string;
  phoneCountryCode: CountryCodeObj;
  emergencyPhoneCountryCode: CountryCodeObj;
  personalInformation:PersonalInformationType;
  inputRef: RefObject<HTMLInputElement>;
  userDetails: UserDetails;
  contryCodeValue:string | undefined;
  countryCodes: CountryInfo[];
  emercontryCodeValue:string | undefined;
  passportErrorMessage: string;
  isPassportErrorValid:boolean;
  isEmailErrorValid:boolean;
  emailErrorMessage:string;
  shouldBlockNavigation: boolean,
  AssociateName: Array<{id:number, name:string}>,
  BackButtonText: string,
  StundetData: {
    id: string,
    type: string;
    student_id: string;
    first_name: string;
    last_name: string;
    gender: string;
    country: string;
    country_code: string | null;
    marital_status: string;
    state: string;
    emergency_contact_email: string,
    city: string;
    mobile: string;
    email: string;
    emergency_contact_person: string;
    emergency_contact_mobile: string;
    current_status: string;
    passport_number: string;
    associate_branch_name: string;
    created_at: string;
    updated_at: string;
    agent_name: string;
    agent_id: string;
    travel_history: Array<{ date: string, country: string, fullForm: string }>;
    visa_refusal: Array<{ date: string, country: string, fullForm: string }>;
    documents: {
      data: Array<{
        Name: string, Id: string,
        FileType: string, DocumentCategory: string,
        AccountId: string, Comment: string,
        Status: string, FileUrl: string, FileSize: string
      }>
    };
    image: string | null;
    country_of_nationality: string,
    country_of_residence: string,
  },
}

interface InvalidResponseType {
  errors: string;
};
interface InvalidResponseErrorType {
  errors: [
      {
          doc: string;
      }
  ]
};

interface SS {}

export default class FormApprovalWorkflowController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCountryCodeAPICallID: string = "";
  validateMobileNoAPICallID: string = "";
  validateEmergencyContactMobileAPICallID: string = "";
  validatePassportAPICallID: string = "";
  getAssociateBranchNamesAPICallID: string = "";
  getApicallData: string = "";
  studentFormApiCallId:string = "";
  validateEmailAPICallID: string = "";
  getProfileAPICallId: string = "";
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      showError:false,
      cityName: "",
      cityNameError: false,
      cityNameErrorTxt: "*Please select the city",
      educationDetailsFile:null,
      educationDetailsErrorTxt:"",
      educationDetailsError:false,
      userToken:"",
      emergencyError:false,
      emergencyFile: [],
      emergencyErrorTxt:"*Please upload emergency document",
      englishTestScoreError:false,
      englishTestScoreFile:null,
      englishTestScoreErrorTxt:"",
      workExperienceError:false,
      workExperienceFile:null,
      workExperienceErrorTxt:"",
      passportError:false,
      passportFile:null,
      passportErrorTxt:"",
      passportAndTravelHistory:{
        visaRefusals:[{
          country:"none",
          date:""
        }],
        travelHistories:[{
          country:"none",
          date:""
        }],
      },
      emergencyAndOtherDocument:{
        emergencyPersonName:"",
        emergencyContact:"",
        remarks:"",
        emergencyEmail:"",
      },
      toggleDrawer: false,
      countryErrorTxt: "*Please select the country",
      stateValue: "none",
      countryError: false,
      countryResErrorTxt: "*Please select the country",
      countryResError: false,
      stateData: [],
      countryValue: "none",
      countryValue_res:"none",
      countryValue_nation:"none",
      contactNumber: "",
      stateError: false,
      stateErrorTxt: "*Please select the state",
      contactNumberError: false,
      contactEmerNumberErrorTxt:"Contact Number is required",
      contactNumberErrorTxt: "*Contact Number is required",
      countryData: Country.getAllCountries() as never,
      destination: { destination_latitude: "", destination_longitude: "" },
      activeStep:0,
      cutomerData: {},
      personalInformation:{
        firstName: "",
        lastName: "",
        gender: "",
        maritalStatus: "",
        email: "",
        passport: "",
        associateBranchName:""
      },
      phoneCountryCode: {} as CountryCodeObj,
      customerID: "",
      successPopUp:false,
      emergencyNumberError: false,
      emergencyPhoneCountryCode: {} as CountryCodeObj,
      inputRef: createRef(),
      userDetails: {} as UserDetails,
      countryCodes:[],
      contryCodeValue:"",
      emercontryCodeValue:"",
      passportErrorMessage: "",
      isPassportErrorValid:false,
      isEmailErrorValid:false,
      emailErrorMessage:"",
      shouldBlockNavigation: true,
      AssociateName: [],
      BackButtonText: "are you sure you want to leave?",
      StundetData: {
        id: '',
        type: '',
        student_id: "",
        first_name: "",
        last_name: "",
        gender: "",
        country: "",
        country_code: "",
        marital_status: "",
        state: "",
        city: "",
        mobile: "",
        email: "",
        emergency_contact_person: "",
        emergency_contact_mobile: "",
        current_status: "",
        passport_number: "",
        associate_branch_name: "",
        created_at: "",
        updated_at: "",
        agent_name: "",
        agent_id: "",
        travel_history: [],
        visa_refusal: [],
        documents: { data: [] },
        image: "",
        country_of_nationality: "",
        country_of_residence: "",
        emergency_contact_email: ""
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived in");
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiFormSuccessCall(apiRequestCallId, responseJson);
      }
      if (responseJson && responseJson.errors) {
        this.apiFailureCall(apiRequestCallId, responseJson);
      }
      if (apiRequestCallId === this.getAssociateBranchNamesAPICallID) {
        this.setAssociateNameResponse(responseJson.data)
      }
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token")
    this.setState({ userToken: token }, () => this.getFormApprovalProfile())
    await this.getCountryCode()
    await this.getAssociateBranchNames()
    this.prioritizeIndia(this.state.countryData)
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation && !this.state.successPopUp) {
      window.onbeforeunload = () => {
        this.setState({
          shouldBlockNavigation: true
        })
      }
    }
  }

showPopup = (message: any, isError: boolean = false, response:any) => {
  if (isError) {
    alert(message);
  } else {
    this.setState({ successPopUp: true, StundetData: response.student}); 
  }
};

  // web events

  setDefaultValue = () => {
    this.setState({
      showError:false,
      emergencyFile: [],
      educationDetailsFile:null,
      cityName: "",
      englishTestScoreFile:null,
      workExperienceFile:null,
      passportFile:null,
      passportAndTravelHistory:{
        visaRefusals:[{
          country:"none",
          date:""
        }],
      travelHistories:[{
          country:"none",
          date:""
        }],
      },
      emergencyAndOtherDocument:{
        emergencyContact:"",
        emergencyPersonName:"",
        emergencyEmail:"",
        remarks:"",
      },
      stateValue: "none",
      toggleDrawer: false,
      countryValue: "none",
      countryValue_res:"none",
      countryValue_nation:"none",
      stateData: [],
      contactNumber: "",
      activeStep:0,
      personalInformation:{
        firstName: "",
        lastName: "",
        gender: "",
        maritalStatus: "",
        email: "",
        passport: "",
        associateBranchName:""
      },
      phoneCountryCode: {} as CountryCodeObj,
      emergencyPhoneCountryCode: {} as CountryCodeObj,
    })
  }

  handleCountryChange_res = (newValue:any) => {
    console.log(newValue,'neww')
    const statedata = State.getAllStates();
    const selectedCountry = newValue ? newValue.isoCode : "none";
  
    this.setState({ stateValue: "none", countryValue_res: selectedCountry }, () => {
      const filteredData = statedata.filter(
        (data: { countryCode: string }) => data.countryCode === selectedCountry
      );
  
      if (filteredData.length === 0) {
        this.setState({
          stateData: [{ name: "N/A" }],
          countryErrorTxt: "",
          countryError: false,
        });
      } else {
        this.setState({
          stateData: filteredData,
          countryErrorTxt: "",
          countryError: false,
        });
      }
    });
  };
  

handleCountryChange_nation = (event: React.ChangeEvent<{}>, newValue: any | null) => {
  const statedata = State.getAllStates();
  const countryCode = newValue ? newValue.isoCode : "none";

  this.setState({ countryValue_nation: countryCode }, () => {
    const filteredData = statedata.filter((data: { countryCode: string }) => data.countryCode === countryCode);
    if (filteredData.length === 0) {
      this.setState({
        stateData: [{ name: "N/A" }],
        countryErrorTxt: "",
        countryError: false,
      });
    } else {
      this.setState({
        stateData: filteredData,
        countryErrorTxt: "",
        countryError: false,
      });
    }
  });
};



verifyFile = (file: File | null, errorTxt: string, errorState: string,error:string) => {
  if (file) {
    this.nextButtonClick();
  } else {
    this.setState((prevState)=>({
      ...prevState,
      [errorTxt]: `*Please upload ${error} file`,
      [errorState]: true
    }));
  }
}

 isValidPhoneNumber(phoneNumber:string) {
  return  phoneNumber.length === 10
}

  isValidEmergencyyEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(this.state.emergencyAndOtherDocument.emergencyEmail)
  }

  validateVisaData = (data:{
    visaRefusals:TravelType[];
    travelHistories:TravelType[];
  }) => {
  // Helper function to check if country and date are valid
  const isValidCountryDatePair = (country: string, date: string) => {
    if (country && country !== 'none' && country !== '' && !date) {
      return { valid: false, message: 'Date is required when a country is selected.' }; 
    }
    if (date && date !== '' && (country === 'none' || country === '')) {
      return { valid: false, message: 'Country cannot be "none" or empty when a date is provided.' };
    }
    return { valid: true, message: '' };
  }

  const errors: string[] = [];

  const validateEntries = (entries: any[], label: string) => {
    entries.forEach((entry: { country: any; date: any; }, index: number) => {
      const { valid, message } = isValidCountryDatePair(entry.country, entry.date);
      if (!valid) {
        errors.push(`${label} ${message}`);
      }
    });
  }

  validateEntries(data.visaRefusals, 'Visa Refusals');
  validateEntries(data.travelHistories, 'Travel History');

  if (errors.length > 0) {
    toast.error(errors.join('\n'))
  }

  return errors.length === 0;
  }

verifyPassportTravels = () => {
  const isValid = this.validateVisaData(this.state.passportAndTravelHistory);
  if (this.state.passportFile && isValid) {
    this.setState({ showError: false });
    this.nextButtonClick();
  } else {
    this.setState({
      showError: true,
      passportErrorTxt: "*Please upload Passport & Travel History",
      passportError: !this.state.passportFile
    });
  }
}

  verifyEducationDetails = () => {
    this.verifyFile(this.state.educationDetailsFile,
      'educationDetailsErrorTxt',
      'educationDetailsError',
      'Education Detail'
    );
  }

verifyEnglishTestScore = () => {
  this.verifyFile(this.state.englishTestScoreFile,
    'englishTestScoreErrorTxt',
    'englishTestScoreError',
    'English Test Score'
  );
}

verifyWorkExperience = () => {
  this.nextButtonClick();

}

verifyPassportTravel = () => {
  this.verifyFile(this.state.passportFile,
    'passportErrorTxt',
    'passportError',
    'Passport Travel'
  );
}

verifyEmergencyData = () => {
  this.verifyFile(this.state.emergencyFile[0],
    'emergencyTxt',
    'emergencyError',
    'Emergency Contact'
  );
}


handleClose = () => {
  this.setState({successPopUp:false})
  this.setDefaultValue()
  this.navigateToStudentFlow();
};

navigateToStudentFlow = () => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "AddApplication"
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
}

addVisaRefusalFields = () => {
  const tempData = [...this.state.passportAndTravelHistory.visaRefusals,
  { country: "none", date: "" }]
  this.setState({ passportAndTravelHistory: { ...this.state.passportAndTravelHistory, visaRefusals: tempData } })
}

removeVisaRefusalFields = (index:number) => {
  const tempData = this.state.passportAndTravelHistory.visaRefusals.filter((visa,indexValue)=>index !== indexValue)
this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,visaRefusals:tempData}})
}

removeTravelhistory = (index:number) => {
  const tempData = this.state.passportAndTravelHistory.travelHistories.filter((visa,indexValue)=>index !== indexValue)
this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,travelHistories:tempData}})
}

addVisaRefusalCountry = (event: any, id: number, countryIsoCode: string) => {
  const tempData = this.state.passportAndTravelHistory.visaRefusals.map((visa, index) =>
    index === id
      ? { ...visa, country: countryIsoCode } // Update selected country ISO code
      : visa
  );
  this.setState({
    passportAndTravelHistory: {
      ...this.state.passportAndTravelHistory,
      visaRefusals: tempData as TravelType[],
    },
  });
};

  addVisaRefusalDate = (event: SelectEventType, id: number) => {
    const tempData = this.state.passportAndTravelHistory.visaRefusals.map((visa, index) => index === id
      ? { ...visa, date: event.target.value } : visa)
    this.setState({ passportAndTravelHistory: { ...this.state.passportAndTravelHistory, visaRefusals: tempData as TravelType[] } })
  }

  addTravelHistoryFields = () => {
    const tempData = [...this.state.passportAndTravelHistory.travelHistories,
    { country: "none", date: "" }]
    this.setState({ passportAndTravelHistory: { ...this.state.passportAndTravelHistory, travelHistories: tempData } })
  }

    addTravelHistoryCountry = (event: any, id: number, countryIsoCode: string) => {
      const tempData = this.state.passportAndTravelHistory.travelHistories.map((travel, index) =>
        index === id
          ? { ...travel, country: countryIsoCode }  // Update selected country ISO code
          : travel
      );
      this.setState({
        passportAndTravelHistory: {
          ...this.state.passportAndTravelHistory,
          travelHistories: tempData as TravelType[],
        },
      });
    };
  addTravelhistoryDate = (event: SelectEventType, id: number) => {
    const tempData = this.state.passportAndTravelHistory.travelHistories.map((travel, index) => index === id
      ? { ...travel, date: event.target.value } : travel)
    this.setState({ passportAndTravelHistory: { ...this.state.passportAndTravelHistory, travelHistories: tempData as TravelType[] } })
  }
  
  addEmergencyData = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string, key: string, country?: CountryCodeObj) => {
    const value = typeof event === "string" ? event : event?.target?.value
    this.setState({ emergencyAndOtherDocument: { ...this.state.emergencyAndOtherDocument, [key]: value } })
    if (country) {
      this.setState({ emergencyPhoneCountryCode: country })
    }
  }


    postApiCall = async () => {
      const header = {
    token: this.state.userToken,
    contentType: configJSON.studentFormContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const tempData = {
    student:{
      first_name:this.state.personalInformation.firstName,
      last_name:this.state.personalInformation.lastName,
      gender:this.state.personalInformation.gender,
      marital_status:this.state.personalInformation.maritalStatus,
      country:this.state.countryValue,
      country_of_residence:this.state.countryValue_res,
      country_of_nationality:this.state.countryValue_nation,
      state:this.state.stateValue,
      city:this.state.cityName,
      remarks:this.state.emergencyAndOtherDocument.remarks,
      mobile:this.state.contactNumber,
      email:this.state.personalInformation.email,
      associate_branch_name: this.state.personalInformation.associateBranchName,
      passport_number:  this.state.personalInformation.passport,
      emergency_contact_person:this.state.emergencyAndOtherDocument.emergencyPersonName,
      emergency_contact_mobile:this.state.emergencyAndOtherDocument.emergencyContact,
      emergency_contact_email:this.state.emergencyAndOtherDocument.emergencyEmail
    },
    visa_refusals: this.state.passportAndTravelHistory.visaRefusals,
    travel_histories: this.state.passportAndTravelHistory.travelHistories,
    documents:[
      {
        document_category:"Education Documents",
        name: this.state.educationDetailsFile?.name,
        doc:this.state.educationDetailsFile
      },
      {
        document_category:"English Test Score",
        name: this.state.englishTestScoreFile?.name,
        doc:this.state.englishTestScoreFile
      },
      {
        document_category:"Work Experience",
        name: this.state.workExperienceFile?.name,
        doc:this.state.workExperienceFile
      },
      {
        document_category:"Passport Travel",
        name: this.state.passportFile?.name,
        doc:this.state.passportFile
      },
    ]
  }

  const formData = new FormData();
  formData.append("[student][country_code]", this.state.contryCodeValue as string);
  formData.append("[student][emergency_country_code]", this.state.emercontryCodeValue as string);
  Object.keys(tempData.student).forEach((student, index) => {
    formData.append(`[student][${student}]`, Object.values(tempData.student)[index]);
  });

 tempData.visa_refusals.forEach((visa, index) => {
  if (visa.country !== "none" || visa.date !== "") {
    formData.append(`[visa_refusals][${index}][country]`, visa.country);
    formData.append(`[visa_refusals][${index}][date]`, visa.date );
  }
});

tempData.travel_histories.forEach((travel, index) => {
  if (travel.country !== "none" || travel.date !== "") {
    formData.append(`[travel_histories][${index}][country]`, travel.country);
    formData.append(`[travel_histories][${index}][date]`, travel.date);
  }
});

tempData.documents.forEach((document, index) => {
formData.append(`[documents][${index}][document_category]`, document.document_category);
formData.append(`[documents][${index}][name]`, document.name || 'example doc'); 
formData.append(`[documents][${index}][doc]`, document.doc || ''); 
});

this.state.emergencyFile.forEach((item,index) => {
  formData.append(`[documents][${tempData.documents.length+index}][document_category]`, "Emergency Contact");
  formData.append(`[documents][${tempData.documents.length+index}][name]`, item.name || ''); 
  formData.append(`[documents][${tempData.documents.length+index}][doc]`, item); 
})

  this.studentFormApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.studentFormEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.studentFormApimethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  postStudentFormApi = async () => {
    if (this.state.emergencyAndOtherDocument.emergencyContact === "" ||
      this.state.emergencyAndOtherDocument.emergencyEmail === "" ||
      this.state.emergencyAndOtherDocument.emergencyPersonName === ""
    ) {
      this.setState({ showError: true, emergencyError: false, })
    } else if (this.state.emergencyAndOtherDocument.emergencyContact !== "") {
      this.setState({ showError: false, emergencyError: false, emergencyNumberError: false, })
      this.postApiCall()
    }
  };

handleToggle = () => {
  this.setState({
    toggleDrawer: !this.state.toggleDrawer
  });
};

checkFieldError = () => {
  const fieldsToCheck = [
    {
      value: this.state.countryValue_nation,
      errorTxt: '*Please select the Country of Nationality',
      errorState: 'countryError',
      setStateFn: this.setState.bind(this)
    },
    {
      value: this.state.countryValue_res,
      errorTxt: '*Please select the Country of Residence',
      errorState: 'countryResError',
      setStateFn: this.setState.bind(this)
    },
    {
      value: this.state.stateValue,
      errorTxt: '*Please select the State',
      errorState: 'stateError',
      setStateFn: this.setState.bind(this)
    },
    {
      value: this.state.cityName,
      errorTxt: '*City is required',
      errorState: 'cityNameError',
      setStateFn: this.setState.bind(this)
    },
    {
      value: this.state.contactNumber,
      errorTxt: '*Contact Number is required',
      errorState: 'contactNumberError',
      setStateFn: this.setState.bind(this)
    }
  ];

  fieldsToCheck && fieldsToCheck.forEach(field => {
    if (field?.value?.length === 0 || field?.value === "none") {
      this.setState((prevState) => ({
        ...prevState,
        [`${field.errorState}Txt`]: field.errorTxt,
        [field.errorState]: true
      }));
    }
  });
}


nextButtonClick = () => {
  window.scroll({
    top: 0,
    left: 0
  })
  if(this.state.activeStep < 6) {
    if(this.state.activeStep==0){
       this.setState({
        activeStep:1
      })
    }
    this.setState({
      activeStep:this.state.activeStep + 1
    })
  }else {
    this.setState({
      activeStep: 6
    })
  }
};

disableNumericValue = (event: KeyboardEvent) => {
  const key = event.key;
  if (!/^[a-zA-Z ]$/.test(key) || (!isNaN(parseInt(key)) && key !== '.')) {
    event.preventDefault();
  }
};

backButtonClick = () => {
  
  if(this.state.activeStep > 1) {
    this.setState({
      activeStep:this.state.activeStep - 1,
      showError:false
    })
  }else {
   
    this.setState({
      activeStep: 1,
      showError:false
    })
  }
}

backButtonClickCustom=()=>{
  this.props?.navigation?.navigate("LeadManagement")
}

backButtonClickCustom2=()=>{
  this.props&& this.props.navigation && this.props.navigation.navigate("LeadManagement/2")
}

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

onImageChange = (event: ChangeEvent<HTMLInputElement>, page: string) => {
  const pageStateMap:PageStateMap = {
    educationDetails: {
      file: 'educationDetailsFile',
      errorTxt: 'educationDetailsErrorTxt',
      error: 'educationDetailsError'
    },
    englishTestScore: {
      file: 'englishTestScoreFile',
      errorTxt: 'englishTestScoreErrorTxt',
      error: 'englishTestScoreError'
    },
    workExperience: {
      file: 'workExperienceFile',
      errorTxt: 'workExperienceErrorTxt',
      error: 'workExperienceError'
    },
    passportAndTravels: {
      file: 'passportFile',
      errorTxt: 'passportErrorTxt',
      error: 'passportError'
    },
    emergencyData: {
      file: 'emergencyFile',
      errorTxt: 'emergencyErrorTxt',
      error: 'emergencyError'
    }
  };

  if (event.target.files && event.target.files[0]) {
    const { file, errorTxt, error } = pageStateMap[page];
    const maxSize = 10 * 1000 * 1024;
    const allowedFormats = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (event.target.files[0].size > maxSize) {
      this.setState(prevState => ({
        
        ...prevState,
        [errorTxt]: "*Maximum file size should be 10Mb",
        [error]: true
      }));
    } else if (!allowedFormats.includes(event.target.files[0].type)) {
      this.setState(prevState => ({
        ...prevState,
        [errorTxt]: "*Only PNG, JPG, Doc/.Docx and PDF formats are allowed",
        [error]: true
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [file]: event.target.files ? event.target.files[0] : null,
        [errorTxt]: "",
        [error]: false
      }));
    }
  }
}

  handleContactNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target && target.value !== undefined) {
      const contactNumber = target.value.replace(/\D/g, "").trim();
      if (contactNumber === "") {
        this.setState({
          contactNumber: "",
          contactNumberError: true,
          contactNumberErrorTxt: '*Contact Number is required'
        });
      } else {
        this.setState({
          contactNumber,
          contactNumberError: false,
          contactNumberErrorTxt: ''
        },()=>{
          this.validateMobileNo()
        });
      }
    }
};


handleEmergencyContactNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const contactNumber = event.target.value.replace(/\D/g, "").trim();
    this.setState(prevState => ({
      emergencyAndOtherDocument: {
        ...prevState.emergencyAndOtherDocument,
        emergencyContact: contactNumber
      },
      contactEmerNumberErrorTxt:""
    }));
};

handleCityChange = (event: { target: { value: string; }; }) => {
  const cityName = event.target.value;
  if (cityName === "") {
      this.setState({
          cityName: "",
          cityNameError: true,
          cityNameErrorTxt: 'City is required.'
      });
  } else {
      this.setState({
          cityName: cityName,
          cityNameError: false,
          cityNameErrorTxt: ''
      });
  }
};

handleStateChange = (event: SelectEventType) => {
  this.setState({ stateValue: event.target.value as string, stateError: false });
};

handleClick = () => {
  return this.state.inputRef.current
    ? this.state.inputRef.current.click()
    : "";
};

formApprovalApiCall = async (apiData: APIPayloadType) => {
  const { contentType, method, body, type,endPoint } = apiData;
  let token = await getStorageData("token")
  const header = {
    "Content-Type": contentType,
    token: token
  };
  const requestMessageForm = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessageForm.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessageForm.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessageForm.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  body && type !== "formData"
    ? requestMessageForm.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    : requestMessageForm.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

  runEngine.sendMessage(requestMessageForm.id, requestMessageForm);
  
  return requestMessageForm.messageId;
};

apiFormSuccessCall = (apiRequestCallId: string, responseJson: UserDetailsResponse) => {
  if (apiRequestCallId === this.getProfileAPICallId) {
    this.getProfileFormSuccessCallBack(responseJson);
  }
  if(apiRequestCallId === this.getCountryCodeAPICallID) {
    this.setCountryCodes(responseJson)
  }
  if (apiRequestCallId === this.studentFormApiCallId) {

    if (responseJson) {
      let errorMessage = "";
      let successMessage = "";
      if (responseJson.error) {
        if (Array.isArray(responseJson.error)) {
          errorMessage = responseJson.error.join(". ");
        } else {
          errorMessage = responseJson.error;
        }
        // Show error message via alert
        this.showPopup(`Error: ${errorMessage}`, true, responseJson);
      } else {
        // Show success message if there are no errors
        successMessage = responseJson.meta?.message || "Form submitted successfully!";
        this.showPopup(successMessage, false, responseJson);
      }
      this.studentFormApiCallId = "";
    }
  }
  
  
};

apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType & InvalidResponseErrorType) => {
  if (apiRequestCallId === this.validateEmailAPICallID) {
      this.setEmailValidation(responseJson);
  }
  if(apiRequestCallId === this.validatePassportAPICallID){
    this.setPassportValidation(responseJson)
  }
  if(apiRequestCallId === this.validateMobileNoAPICallID){
    this.setContactErrors(responseJson)
  }
  if(apiRequestCallId === this.validateEmergencyContactMobileAPICallID){
    this.setContactErrors(responseJson)
  }
};

isValidResponse = (responseJson: ValidResponseType) => {
  return (responseJson && responseJson.data) || responseJson.message || [];
};

setCountryCodes = (responseJson: any) => {
  this.setState({countryCodes:responseJson})
  const defaultCountry = this.state.countryCodes.find((country) => country.alpha2 === 'IN');
  this.setState({contryCodeValue:`${defaultCountry?.country_code}-${defaultCountry?.alpha2}`, emercontryCodeValue:`${defaultCountry?.country_code}-${defaultCountry?.alpha2}`})
};

  setAssociateNameResponse = (response: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string; 
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  ) => {
    const data = response.map((item) => {
      return {
        id: item.attributes.id,
        name: item.attributes.branch_name
      }
    })
    this.setState({
      AssociateName: data
    })
  }

setContactErrors = (responseJson: any) => {
    const errorMessage = responseJson.errors?.[0]?.message
    this.state.activeStep === 0 ? this.setState({contactNumberError: true, contactNumberErrorTxt:errorMessage}):this.setState({emergencyNumberError: true, contactEmerNumberErrorTxt:errorMessage})
    this.validateMobileNoAPICallID = ""
}

setPassportValidation = (responseJson: any) => {
  this.setState({ passportErrorMessage: responseJson?.errors?.message, isPassportErrorValid: true })
};

setEmailValidation = (responseJson: any) => {
  this.setState({ emailErrorMessage: responseJson?.errors, isEmailErrorValid: true })
};

getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
  this.setState({ userDetails: responseJson.data });
};

getFormApprovalProfile = async () => {
  let userDetails = await getStorageData("userDetails")
  this.getProfileAPICallId = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.getProfileEndPoint + userDetails
  });
};

removeEducationFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({educationDetailsFile:null})
}

removeEnglishTestScoreFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({englishTestScoreFile:null})
}

removeWorkExperienceFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({workExperienceFile:null})
}

removePassportFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({passportFile:null})
}
removeEmergencyFile = (
  index:number) => {
  const filteredFiles = this.state.emergencyFile.filter((_, currIndex) => currIndex !== index);
  this.setState({ emergencyFile: filteredFiles });
}

getCountryCode = async () => {
  this.getCountryCodeAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.getCountryCode
  });
}

getAssociateBranchNames = async () => {
  this.getAssociateBranchNamesAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.getAssociateBranchNames
  });
}

validateMobileNo = () => {
  if (this.state.contactNumber && this.state.contryCodeValue) {
    this.validateMobile(this.state.contactNumber, this.state.contryCodeValue);
  }
}

validateEmerMobileNo = () => {
  if (this.state.emergencyAndOtherDocument.emergencyContact && this.state.emercontryCodeValue) {
    this.validateEmergencyContactMobile(this.state.emergencyAndOtherDocument.emergencyContact, this.state.emercontryCodeValue);
  }
}

validateMobile = async (mobileno:string, countrycode:string) => {
  this.validateMobileNoAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.validateMobileNumber}?mobile=${mobileno}&country_code=${countrycode}`
  });
}
validateEmergencyContactMobile = async (mobileno:string, countrycode:string) => {
  this.validateEmergencyContactMobileAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `/bx_block_dashboard/students/emergency_mobile_number?emergency_country_code=${countrycode}&emergency_contact_mobile=${mobileno}`
  });
}

handleCountryCodeChange = (event: SelectEventType) => {
  this.setState({ contryCodeValue: event.target.value as string, stateError: false });
};

handleEmerCountryCodeChange = (event: SelectEventType) => {
  this.setState({ emercontryCodeValue: event.target.value as string, stateError: false });
};

validatePassportNo = (event: React.FocusEvent<HTMLInputElement>) => {
  this.setState({passportErrorMessage:""})
  this.setState({isPassportErrorValid: false})
  if (event?.target?.value) {
    this.validatePassport(event?.target?.value);
  }
}

validatePassport = async (passportNo:string) => {
  this.validatePassportAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.validatePassport}?data[attributes][passport_number]=${passportNo}`
  });
}

validatePersonalEmail = (event: React.FocusEvent<HTMLInputElement>) => {
  this.setState({ emailErrorMessage: "" })
  this.setState({ isEmailErrorValid: false })
   const emailRegex = configJSON.emailRegex
  if(!emailRegex.test(event?.target?.value)){
    this.setState({ emailErrorMessage: "*A valid email id is required"})   
    this.setState({ isEmailErrorValid: true })
  }
  if (event?.target?.value) {
    this.validateEmail(event?.target?.value);
  }
}

validateEmail = async (emailid:string) => {
  this.validateEmailAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.validateEmail}?data[attributes][email]=${emailid}`
  });
}

navigateToOtherService = () => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "OtherServices"
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
};

navigateToAllCourses = () => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));

  setStorageData("CourseFinderData", JSON.stringify({
    id: this.state.StundetData.student_id,
    firstName: this.state.StundetData.first_name,
    lastName: this.state.StundetData.last_name,
    image: this.state.StundetData.image ?? '',
    Studentid: this.state.StundetData.id,
    passport_number: this.state.StundetData.passport_number ?? ''
  }))
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "Catalogue"
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
}

NavigationTextCondiation = (values:{[key:string]: string}) =>{
  const Data = Object.values(values).map((item)=> item === '')
  const DataTwo = Data.includes(false)
    if(DataTwo){
      if (this.state.BackButtonText !== "You have unsaved changes, are you sure you want to leave?") {
        this.setState({
          BackButtonText:"You have unsaved changes, are you sure you want to leave?"
        })
      }
    }else{
      if (this.state.BackButtonText !== "are you sure you want to leave?") {
        this.setState({
          BackButtonText:"are you sure you want to leave?"
        })
      }
    }
}
 prioritizeIndia=(data:CountryInterfaceData[])=> {
  const indiaIsoCode = "IN";
  const indiaData = data.filter(item => item.isoCode === indiaIsoCode);
  const otherData = data.filter(item => item.isoCode !== indiaIsoCode);
  this.setState({countryData:[...indiaData, ...otherData]}) ;
}

handleEmeFileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files && event.target.files.length > 0) {
    this.setState({
      emergencyFile: [...this.state.emergencyFile, event.target.files[0]],
    });
  }
};
}

// Customizable Area End
