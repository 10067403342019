Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LeadManagement";
exports.labelBodyText = "LeadManagement Body";
exports.btnExampleTitle = "CLICK ME";
exports.btnTitle = "Submit";
exports.openCamera = "Open Camera";
exports.selectFromGallery = "Select From Gallery";
exports.cancel = "Cancel";
exports.headerTitle = "Lead Management";
exports.typeText = "text";
exports.typeDropdown = "dropdown";
exports.typeCheckbox = "checkbox";
exports.typeRadio = "radio";
exports.typeTextArea = "textarea";
exports.typeSubmitButton = "submit button";
exports.getInputDataEndPoint = "bx_block_leadmanagement/forms/4";
exports.postInputData = "bx_block_leadmanagement/create";

exports.employeesApiContentType = "application/json"
exports.employeesApiMethodType = "GET";
exports.employeesApiEndPoint  = "bx_block_dashboard/students"

exports.countriesApiContentType = "application/json"
exports.countriesApiMethodType = "GET";
exports.countriesApiEndPoint  = "bx_block_bulk_uploading/university/fetch_countries"

exports.countriesRelatedDataApiContentType = "application/json"
exports.countriesRelatedDataApiMethodType = "GET";
exports.countriesRelatedDataApiEndPoint     = "bx_block_bulk_uploading/university/fetch_university_by_country?token={authToken}&country={countryName}"

exports.createdByDataApiContentType = "application/json"
exports.createdByDataApiMethodType = "GET";
exports.createdByDataApiEndPoint  = "account_block/accounts/agent_listing"

exports.addApplicationContentType = "multipart/form-data";
exports.addApplicationApimethod = "POST";
exports.addApplicationEndPoint = "bx_block_dashboard/student_applications";

exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter"
exports.addApplicationTxt = "Add Applications";
exports.editApplicationTxt = "Edit Applications";
exports.starTxt = "*";
exports.employeesTxt= "Student";
exports.select= "Select";
exports.myPreferredTxt= "My Preferred";
exports.expertTxt= "Expert to Advise";
exports.whereStudentTxt = "Where student wants to go?";
exports.selectCountryPlaceholderTxt = "Select Country";
exports.selectCountry = "Where student wants to go?";
exports.selectCountryplaceholder   = "Select Country";
exports.preferCollegeTxt = "Select Preferred College";
exports.preferCollegeplaceHolder = "Select Preferred College";
exports.preferCourseTxt = "Prefer Course";
exports.preferCoursePlaceHolder = "Select Preferred Course";
exports.intakeTxt = "Intake";
exports.yearTxt = "Year";
exports.selectIntakeTxt = "Select Intake";
exports.createdByTxt = "Created By";
exports.createdByNameTxt = "Dinesh Anand";
exports.saveTxt = "Save Changes";
exports.cancelTxt = "Cancel";
exports.remarkTxt = "Remark";
exports.remarkPlaceHolder = "Write something here...";
exports.regaxText = /^[A-Za-z]+$/;
exports.selectYear = "Year";
exports.intakeData = "Sep,Jan,May";
exports.tutionFeeData = "18300 CAD";
exports.applicationFee = "Application Fee:";
exports.duration = "Duration:";
exports.intake = "Intake:";
exports.durationData = "24 Months";
exports.clgWebsiteLink = "https://www.alexandercollege.ca";
exports.clgName = "Alexander College";
exports.clgWebsiteHeader = "College Website";
exports.courseName = "Associate of Arts (Sociology)";
exports.courseLink = "https://alexandercollege.ca/programs-and-courses/associate-of-arts-sociology/";
exports.tutionFee = "Tuition Fee:";
exports.applicationFeeData = "200 CAD";
exports.defaultYear = "2023";
exports.addStudentApplications = "Add Student Application";
exports.sno = "S.No";
exports.studentName = "Student";
exports.countryName = "Country & State";
exports.cityName = "City";
exports.applicationNumber = "No. of Application";
exports.currentStatus = "Current Status";
exports.createdBY = "Created By";
exports.studentAddDate = "Date of Student Added";
exports.lastActionDate = "Date of Last Action";
exports.action = "Action";
exports.close = "Close";
exports.success = "Success";
exports.successMessage = "Your application has been added.";
exports.getProfileEndPoint = "/account_block/accounts/";
exports.snoTxt = "S.No";
exports.student = "Student";
exports.passport = "Passport No";
exports.activeApplication = "Active Application";
exports.associateBranch = "Associate Branch";
exports.createdBy = "Created By";
exports.countryAndState = "Country & State";
exports.cityNames = "City";
exports.dateOfLastAction = "Date of Last Action";
exports.dateOfStudentAdded = "Date of Student Added";
exports.showingResult = "Showing result";
exports.actionTxt = "Action";
exports.viewApp = "View App";
exports.addStudentTxt = "Add New Student";
exports.filterTxt = "Filter";
exports.autoCompleteStudent = "Select Student (s)";
exports.selectBranchPlaceholder = "Select Branch";
exports.selectCreatedByPlaceholder = "Select Counsellor (s)";
exports.selectStudentAddedByPlaceholder = "Choose Period";
exports.fetchFilterData = "bx_block_dashboard/set_student_filter";
exports.sendFilterData = "bx_block_dashboard/student_filter";
exports.noStudentTxt = "No students are added yet"
exports.addStudentTxtPara = "Please add new student by clicking on the button below"
exports.addStudentBtnTxt = "Add New Student"
exports.getProfileEndPoint = "account_block/accounts/";
exports.starTxt = "*";
exports.getAssociateBranchNames = "/account_block/manage_branches";
exports.getCountryCode = "/bx_block_dashboard/students/get_country_code";
exports.validatePassport = "bx_block_dashboard/students/passport_exist";
exports.passPortRegex = /^[A-Z][1-9][0-9]\s?[0-9]{4}[1-9]$/;
exports.passportValidationMsg = "Invalid Passport Number. Please enter a valid passport number.";
exports.validateMobileNumber = "/bx_block_dashboard/students/check_mobile_number";
exports.httpGetMethod = "GET";
exports.searchApiContentType = "application/json";
exports.getAssignStaffEndPoint = "/bx_block_dashboard/students/assigned_staff";
exports.courseInfoEndPoint = "/bx_block_catalogue/catalogues/course_information"
exports.otherAppsEndPoint = "/bx_block_catalogue/catalogues/other_application"
exports.AppResEndPoint = "/bx_block_leadmanagement/forms/get_application_requirement"
exports.documentsFetch = "/bx_block_leadmanagement/forms/institute_documents"
exports.getOtherServiceEndPoint = "bx_block_dashboard/other_services";
exports.descriptionThreeTxt = "Esctetur adipiscing elit. Aenean iaculis cursus tellus placerat pretium. Morbi lectus orci, maximus a commodo non, consequat non risus hguyt.";
exports.descriptionFourTxt = "Jnean iaculis cursus tellus placerat pretium. Morbi lectus orci, maximus a commodo non, conseq.";
exports.headingThreeTxt = "Airlines Ticket Booking (Air Saathi)";
exports.headingFourTxt = "Buy PTE Vouchers";
exports.bookeAirLineTicket = "Book Airline Ticket";
exports.voucherTxt = "Buy Voucher";
exports.postApiMethod = "POST";
exports.openAccountEndpoint = "bx_block_dashboard/other_services/add_student_service";
exports.formData = "formData";
exports.deleteSHortlistData="bx_block_catalogue/catalogues/delete_course";
exports.totalConv="bx_block_conversationthreading2/comments/total_conversation";
exports.eligibility="bx_block_leadmanagement/forms/eligibility";
// Customizable Area End